<template>
  <div class="sai-breadcrumbs">
    <div class="sai-breadcrumbs__top">
      <div class="sai-breadcrumbs__top__item" @click="move(0)">
        <div class="sai-breadcrumbs__top__item__text">
          {{ topText }}
        </div>
      </div>
    </div>
    <div id="sai-bread-content" class="sai-breadcrumbs__content scrollX">
      <div class="sai-breadcrumbs__content__items">
        <div
          class="sai-breadcrumbs__content__items__item"
          :class="[
            `b_${index > 2 ? 3 : index}`,
            'active',
            localIndex === index && 'selected'
          ]"
          v-for="(item, index) in list"
          v-if="index > 0"
          @click="move(index)"
          :style="{ 'z-index': 1000 - index }"
          :key="index"
        >
          <a class="sai-breadcrumbs__content__items__item__text active">
            <span>
              {{ generateTitle(item.text) }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import BreadcrumbsMixin from '@/mixins/BreadcrumbsMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';

@Component({})
export default class Breadcrumbs extends mixins(
  BreadcrumbsMixin,
  ProductTicketMixin
  ) {
  scrollTarget = '.sai-breadcrumbs__content__items__item.selected';
  topText = Const.BREADCRUMB_TOP_TEXT;

  generateTitle(text) {
    return text.length > 10 ? `${text.slice(0, 10)}...` : text;
  }
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);

    const newTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      status: 'open',
    };

    return { ...newTicket, ...this.customizedTicket };
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return {
      ...commonTicket,
      ...ticketParams,
      ...this.customizedTicket
    };
  }
}
</script>
<style lang="scss" scoped>
@import '../style/settings';

$topBgColor: #686868;
$topTextColor: #fff;
$otherItemsTextColor: #BF0000;
$parentBgColor: #EBEBEB;
$childBgColor: #F2F2F2;
$faqBgColor: #F7F7F7;

.sai-breadcrumbs {
  display: block;
  position: relative;
  height: 24px;
  margin-top: 2px;
  font-size: 14px;

  &__top {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: $zIndex;
    cursor: pointer;

    &__item {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      padding: 4px 12px;
      background-color: $topBgColor;

      &__text {
        color: $topTextColor;

        &::after {
          content: ' ';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: 0px;
          left: 100%;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 11px solid $topBgColor;
          z-index: 3;
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 61px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__items {
      display: flex;
      align-items: center;
      height: 100%;
      white-space: nowrap;

      &::after {
        content: '';
        display: block;
        clear: both;
      }

      &__item {
        position: relative;
        height: 100%;

        &__text {
          display: flex;
          align-items: center;
          position: relative;
          height: 100%;
          padding: 3px 10px 3px 22px;
          color: $otherItemsTextColor;

          &::after {
            content: ' ';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0px;
            left: 100%;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            z-index: 3;
          }
        }
        .b_1 & {
          &__text {
            background: $parentBgColor;
            &::after {
              border-left: 11px solid $parentBgColor;
            }
          }
        }
        .b_2 & {
          &__text {
            background: $childBgColor;
            &::after {
              border-left: 11px solid $childBgColor;
            }
          }
        }
        .b_3 & {
          &__text {
            background: $faqBgColor;
            &::after {
              border-left: 11px solid $faqBgColor;
            }
          }
        }
      }
    }
  }
}
</style>
