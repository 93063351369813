<template>
  <ScrollGuide ref="scrollGuide">
    <div
      :class="[
        'ResourceList',
        active && 'active',
        active && 'pageScrollTarget',
        enableScroll && 'scrollY',
        currentValue && currentValue.viewType === 'result' && 'result',
        currentValue && currentValue.viewType === 'scenario' && 'scenario',
      ]"
      v-scroll="onScroll"
    >
      <div ref="scrollWrapper" class="scrollWrapper">
        <div class="head" v-if="currentValue.viewType !== 'talkScript'">
          <div class="button">
            <a @click.prevent="backToSearch()">
              <div class="icon">
                <img src="../resource/common/next_black.svg" />
              </div>
              <div class="text">
                {{ backToSearchButtonText }}
              </div>
            </a>
          </div>
          <div class="title faq">
            <div class="icon">
              <div class="img"></div>
            </div>
            <div class="text">
              <div class="ancestors">
                <span
                  class="ancester"
                  v-for="(ancester, index) in lastScript.ancesters"
                  :key="index"
                >
                  {{ ancester.text }}
                  <span v-if="index < lastScript.ancesters.length - 1">＞</span>
                </span>
              </div>
              <div class="faq">
                {{ generateTitle(lastScript.text) }}
              </div>
            </div>
          </div>
          <div class="border"></div>
        </div>
        <div class="description">
          <div class="icon"><div class="img"></div></div>
          <div
            class="caption"
            v-show="item && item.caption"
            v-html="item && item.caption"
          ></div>
        </div>
        <div ref="items" class="items">
          <div
            :class="[
              'item',
              isSelected(item) && 'selected',
              'talkScriptType-' + item.talkScriptType,
              firstIndex && 'first',
            ]"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="relation">
              <div class="relation-line"></div>
            </div>
            <div class='itemWrapper'>
              <div
                :class="['itemContent', index === 0 && 'firstItem']"
                @click.prevent="openHandler(item)"
                :item-debug="JSON.stringify(item)"
              >
                <span>{{ item.text }}</span>
                <div class="img"></div>
              </div>
            </div>
          </div>
        </div>
        <ResourceListFooter
          :currentValue="currentValue"
          :lastScript="lastScript"
          @scrollBottom="scrollBottom"
        />
      </div>
    </div>
    <div ref="anchor" class="anchor">
      <span class="anchorBar"></span>
      <span class="anchorBox"></span>
    </div>
  </ScrollGuide>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import textParser from '../libs/textParser';
import Const from '../const/index';
import { eventBus } from '@/eventBus';

@Component({})
export default class ResourceList extends mixins(
  ResourceListMixin,
  ProductTicketMixin
  ) {

  backToSearchButtonText = Const.BACK_TO_SEARCH_BUTTON_TEXT;

  generateTitle(text) {
    return textParser.generateTitle(text);
  }
  existsFaq(text) {
    return textParser.existsFaq(text);
  }
  generateFaq(text) {
    return textParser.generateFaq(text);
  }
  async openHandler(item) {
    if (item.talkScriptId && item.viewType === 'scenario') {
      const scenario = await dataResource.getItem(item);
      Object.assign(item.items, scenario.items);
    }
    this.open(item);
    if (
      item.talkScriptId === '#' ||
      (item.viewType === 'talkScript' && this.$store.state.ticket.resetFlag)
    ) {
      eventBus.$emit('resetTicket');
      return;
    }

    if (item.viewType !== 'talkScript') {
      this.$store.commit('ticket/setResetFlag', true);
    }
    const newTicket = await this.updateTicket(item);
    await this.$ticket.setData({ ...newTicket, ...this.customizedTicket });
    this.$ticket.post();
  }
  async backToSearch() {
    eventBus.$emit('setTagSearchShow', true);
    setTimeout(() => {
      eventBus.$emit('scrollToSearch');
    }, 500);

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }

    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData({ ...newTicket, ...this.customizedTicket });
    this.$ticket.post();
  }
  updateOpenTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateOpenTicket(startTime, query, tagActiveSet);
  }
  generateOpenTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };
    return newTicket;
  }
}
</script>
<style lang="scss" scoped>
@import '../style/settings';
@import '../style/utility/embedHtml';

$mainColor: #BF0000;
$shadowColor: rgba(0, 0, 0, 0.04);
$relationColor: #ccc;
$activeRelationColor: #EBEBEB;

.ResourceList {
  .VerticalNavigation & {
    padding: 15px;
    overflow-y: initial;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .resultContent & {
      padding: 0;
    }
  }
  .ColumnNavigation & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;
    -webkit-overflow-scrolling: touch;
  }
  img {
    display: block;
    max-width: 90%;
    margin: 0 auto;
  }
}

.result {
  .mobile & {
    padding-bottom: 40px;
  }
}

.items {
  .VerticalNavigation & {
    overflow: hidden;
  }
}

.item {
  position: relative;
  .VerticalNavigation & {
    display: block;
    float: left;
    width: 33.333%;
  }
  .ColumnNavigation & {
    &.talkScriptType-leaf {
      .itemIcon {
        height: 20px;
        width: 20px;
        display: inline-block;
        background-image: url(./../resource/n/q.png);
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.itemWrapper {
  padding: 5px 5px 5px 16px;
  .first & {
    padding: 5px;
  }
  .scenario & {
    padding: 5px 10px 5px 25px;
  }
}

.itemContent {
  display: flex;
  align-items: center;
  color: $mainColor;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 30px;
    width: 2px;
  }
  > span {
    display: block;
    flex: 1;
    padding: 12px 10px;
    font-size: 16px;
  }
  .img {
    margin-right: 10px;
    height: 16px;
    width: 8px;
    background-image: url('../resource/net/next.svg');
    background-size: contain;
    background-repeat: no-repeat;

    .scenario & {
      right: 14px;
    }
  }
  > i:before {
    color: #fff;
  }
  .item.selected & {
    background-color: $activeRelationColor;
    box-shadow: 0 0 4px rgba(4, 48, 170, 0.5);
  }
  .VerticalNavigation & {
    position: relative;
    display: inline-block;
    width: 93%;
    margin-left: 8px;
    &:before {
      left: auto;
    }
  }
}

.anchor,
.relation {
  .VerticalNavigation & {
    display: none !important;
  }
}

.anchor {
  position: absolute;
  top: 50px;
  left: 4px;
  &.selected {
    .anchorBar {
      top: -1px;
      border-width: 3px;
      border-color: $activeRelationColor;
    }
    .anchorBox {
      background: $activeRelationColor;
    }
  }
}

.anchorBox {
  position: absolute;
  top: -4px;
  width: 8px;
  height: 8px;
  background: $relationColor;
  border-radius: 4px;
}

.anchorBar {
  position: absolute;
  top: 0;
  right: -5px;
  width: 9px;
  border-top: 1px solid $relationColor;

  .selected & {
    border-top: 1px solid $activeRelationColor;
  }
}

.scrollAnchor {
  left: 10px;
  .anchorBox {
    // background: #f00;
  }
}

.ColumnNavigation {
  [ref='anchor'],
  .scrollGuide {
    display: none;
  }
}

.relation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 8px;

  .first & {
    width: 0;
  }
  .item.selected & {
    top: -1px;
  }
  .scenario & {
    width: 17px;
  }
}
.relation-line {
  position: absolute;
  right: 0;
  left: 0;
  .item.upper & {
    top: 50%;
    bottom: auto;
    border-top: 1px solid $relationColor;
    border-left: 1px solid $relationColor;
    border-top-left-radius: 15px;
  }
  .item.lower & {
    top: auto;
    bottom: 50%;
    border-bottom: 1px solid $relationColor;
    border-left: 1px solid $relationColor;
    border-bottom-left-radius: 15px;
  }
  .item.selected & {
    border-width: 3px;
    border-color: $activeRelationColor;
    z-index: 1;
  }
}

.description {
  display: flex;
  flex-direction: row;

  .result & {
    padding: 10px 0;
  }

  .icon {
    display: none;
    .img {
      width: 24px;
      height: 24px;
      background-image: url('../resource/net/a.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .result & {
      display: block;
      margin-right: 6px;
    }
  }
  .caption {
    white-space: pre-wrap;

    .VerticalNavigation & {
      display: block;
      margin: 24px 0;
      font-weight: bold;
      font-size: 18px;
      color: #4c6874;
      .resultContent & {
        margin-bottom: 4px;
        color: #333;
        font-weight: normal;
        font-size: 16px;
        .hasScenario & {
          color: #4c6874;
        }
      }
    }
    .ColumnNavigation & {
      padding: 10px 10px 10px 27px;
    }
    .result & {
      padding: 2px 0 0 0;
    }
  }
}

// Faq / Scenario タイトル
.head {
  margin: 0 10px 0 25px;
  .result & {
    margin: 0 0 5px 0;
  }

  .button {
    padding: 14px 0 8px 0;

    a {
      display: inline-block;
      padding: 9px 12px 9px 12px;
      border: 1px solid #d1d1d1;
      border-radius: 4px;

      .icon {
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 6px;
        img {
          height: 13px;
          width: 7px;
          max-width: 7px;
        }
      }
      .text {
        display: inline-block;
        line-height: 22px;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;

    .icon {
      position: relative;
      margin-right: 6px;

      .img {
        width: 24px;
        height: 24px;
        background-image: url(../resource/net/q.svg);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .text {
      display: inline-block;
      padding-top: 2px;

      .ancestors {
        color: #686868;
        font-size: 10px;
      }
      .faq {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
  .subInfo {
    text-align: right;

    > span {
      color: #686868;
    }
  }
  .border {
    height: 1px;
    margin-top: 12px;
    border-bottom: solid 1px #d1d1d1;
  }
}
</style>
