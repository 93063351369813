import Vue from 'vue';
import Vuex from 'vuex';
import tagSearch from './modules/tagSearch';
import user from './modules/user';
import history from './modules/history';
import autocomplete from './modules/autocomplete';
import navigation from './modules/navigation';
import resource from './modules/resource';
import ticket from './modules/ticket';

Vue.use(Vuex);

const defaultStore = {
  state: {
    onIME: false,
    ready: false,
    productType: '',
  },
  getters: {
    isReady: state => {
      return state.ready;
    },
  },
  mutations: {
    setIME(state, payload) {
      state.onIME = payload;
    },
    setReady(state, payload) {
      state.ready = payload;
    },
    setProductType(state, payload) {
      state.productType = payload;
    },
  },
  actions: {
    async Initialize({ dispatch, commit }) {
      // module initialize
      await dispatch('resource/init');
      await dispatch('tagSearch/init');
      await dispatch('user/init');
      await dispatch('history/init');
      await dispatch('autocomplete/init');
      // prepare common state
      const productType = process.env.PRODUCT_TYPE || '';
      commit('setProductType', productType);
      commit('setReady', true);
    },
  },
  modules: {
    tagSearch: tagSearch,
    user: user,
    history: history,
    autocomplete: autocomplete,
    navigation: navigation,
    resource: resource,
    ticket: ticket,
  },
  plugins: [],
};

export const generateStore = ({ modules = {}, plugins = [] }) =>
  new Vuex.Store({
    ...defaultStore,
    modules: { ...defaultStore.modules, ...modules },
    plugins: [...defaultStore.plugins, ...plugins],
  });

export default new Vuex.Store(defaultStore);
