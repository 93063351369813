import Vue from 'vue';
import Component from 'vue-class-component';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import { eventBus } from '@/eventBus';
import Const from '../const/index';

@Component({
  props: {},
})
export default class ProductFaqClientTagMixin extends Vue {
  mainTitle = Const.MAIN_TITLE;
  showDefaultHeader = Const.SHOW_DEFAULT_HEADER;
  showHeaderTitle = Const.SHOW_HEADER_TITLE;
  showHeaderIcon = Const.SHOW_HEADER_ICON;

  resultSupportTextPc = Const.RESULT_SUPPORT_TEXT_PC;
  resultSupportTextSp = Const.RESULT_SUPPORT_TEXT_SP;
  serviceTagsTitle = Const.SERVICE_TAGS_TITLE;
  productTagsTitle = Const.PRODUCT_TAGS_TITLE;

  tagProperty = Const.MODE[process.env.BUILD_MODE].tagProperty;
  maxNumOfCandidateTag = 8;

  onModal = false;
  enquete = {};

  get serviceTags() {
    return Const.MODE[process.env.BUILD_MODE].categorizedTags.service;
  }
  get productTags() {
    return Const.MODE[process.env.BUILD_MODE].categorizedTags.product;
  }
  async tagifyTextExact(tagText) {
    await this.$store.dispatch('tagSearch/tagifyTextExact', tagText);
  }

  @AsyncComputed({ default: [] })
  async customizedCandidateTags() {
    // 入力無しの場合
    if (this.inputEmpty) {
      return this.validInitialTags;
    }

    const categoryTags = this.$store.getters['tagSearch/selectedCategoryTags'];
    // 選択カテゴリ無しの場合
    if (categoryTags.length === 0) {
      return this.candidateTags;
    }
    // 選択カテゴリ有りの場合
    const customizedCandidateTags = [];
    for (let item of this.candidateTags) {
      if (!this.isEqualTagText(item, categoryTags[0].text)) {
        customizedCandidateTags.push(item);
      }
      if (customizedCandidateTags.length > this.maxNumOfCandidateTag) {
        return customizedCandidateTags;
      }
    }
    return customizedCandidateTags;
  }
  isEqualTagText(tag, text) {
    return (tag.text && tag.text === text) || (tag.displayText && tag.displayText === text);
  }
  get validInitialTags() {
    const tags = [];
    for (let item of this.$store.state.tagSearch.candidateTags.values()) {
      if (item.tag.attributes && item.tag.attributes.init && item.tag.attributes[this.tagProperty]) {
        tags.push(item);
      }
      if (tags.length > this.maxNumOfCandidateTag) {
        return tags;
      }
    }
    return tags;
  }
  toggleModal(boolean) {
    this.onModal = boolean;
    if (!boolean) {
      this.enquete = {};
    }
  }
  openEnquete(enquete) {
    this.enquete = enquete;
    this.toggleModal(true);
  }
  created() {
    eventBus.$on('openEnquete', enquete => {
      this.openEnquete(enquete);
    });
  }
}
