<template>
  <ListContent :list="list" @selected="selected" />
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import dataResource from '@/common/dataResource';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import { eventBus } from '@/eventBus';
import ListContentWrapperMixin from '@/mixins/ListContentWrapperMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';

@Component({
  components: {},
  props: {},
})
export default class History extends mixins(
  ListContentWrapperMixin,
  ProductTicketMixin
  ) {
  maxNumOfHistories = 10;
  maxNumOfDisplay = 5;

  async selected(item) {
    await this.updateRoutes(item);
    setTimeout(() => {
      eventBus.$emit('scrollToFaqTitle');
    }, 100);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = await this.updateTicket({item: item, faqAction: 'history'});
    await this.$ticket.setData({ ...newTicket, ...this.customizedTicket });
    this.$ticket.post();
  }
  @AsyncComputed()
  async list() {
    const items = [];

    const historyIds = this.$store.getters['history/getScriptHistory'];
    if (historyIds.length === 0) {
      return items;
    }

    const histories = historyIds.slice(0, this.maxNumOfHistories);
    await dataResource.ready({ resourceName: 'talkScript' });
    for (let i = 0; (items.length < this.maxNumOfDisplay && i < histories.length); i++) {
      const item = dataResource.getItemSync({
        resourceName: 'talkScript',
        talkScriptId: histories[i].talkScriptId,
      });
      if (item) {
        items.push(item);
      }
    };
    return items;
  }
}
</script>
