<template>
  <div class="sai-enquete">
    <form class="content input" v-if="isInput">
      <div class="heading">
        <div class="title">
          <div v-html="enquete.lead"></div>
          <div v-html="enquete.label_sp" v-if="isSP"></div>
          <div v-html="enquete.label_pc" v-else></div>
        </div>
        <div class="close" v-if="isInput">
          <a class="icon" @click.prevent="closeEnquete">
            <div class="img"></div>
          </a>
        </div>
      </div>
      <div class="input">
        <textarea
          class="field"
          type="text"
          :rows="rows"
          :placeholder="enquete.placeholder"
          :value="userMessage"
          @input="userMessage = $event.target.value"
        />
      </div>
      <div class="submit">
        <a class="send" @click.prevent="send">{{ enqueteSendText }}</a>
      </div>
    </form>
    <div class="content complete" v-if="isCompleted">
      <div class="afterMessage">
        <div
          class="afterMessage__text"
          v-html="enquete.afterMessage"
        ></div>
      </div>
      <div class="submit">
        <a class="send" @click.prevent="closeEnquete">{{ enqueteCloseText }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import { eventBus } from '@/eventBus';
import Const from '../const/index';


const ENQUETE_TYPE = {
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
};

const ENQUETE_ACTION = {
  SEARCH_FAILED: 'searchFailed',
  SEARCH_NO_SCRIPT: 'searchNoScript',
  FEEDBACK_RESOLVED: 'feedbackResolved',
  FEEDBACK_UNRESOLVED: 'feedbackUnresolved',
};

const ENQUETE_STATUS = {
  INPUT: 'input',
  CONFIRM: 'confirm',
  COMPLETED: 'completed',
};

@Component({
  props: {
    type: String,
    action: String,
    item: Object,
  },
})
export default class Enquete extends mixins(ProductTicketMixin) {
  enquete = {};
  sending = false;
  userMessage = Const.ENQUETE.DEFAULT_USER_MESSAGE;
  enqueteSendText = Const.ENQUETE_SEND_TEXT;
  enqueteCloseText = Const.ENQUETE_CLOSE_TEXT;
  enqueteStatus = ENQUETE_STATUS.INPUT;

  get isInput() {
    return this.enqueteStatus === ENQUETE_STATUS.INPUT;
  }
  get isSP() {
    return this.$store.state.user.isSP;
  }
  get isConfirm() {
    return this.enqueteStatus === ENQUETE_STATUS.CONFIRM;
  }
  get isCompleted() {
    return this.enqueteStatus === ENQUETE_STATUS.COMPLETED;
  }
  get rows() {
    return this.$store.state.product.origin === 'window' ? '6' : '8';
  }

  onInput() {
    this.enqueteStatus = ENQUETE_STATUS.INPUT;
  }
  onConfirm() {
    this.enqueteStatus = ENQUETE_STATUS.CONFIRM;
  }
  onCompleted() {
    this.enqueteStatus = ENQUETE_STATUS.COMPLETED;
  }
  closeEnquete() {
    this.$emit('toggleModal', false);
  }
  async send() {
    this.sending = true;
    try {
      this.$store.commit('ticket/setResetFlag', true);
      const newTicket = await this.updateTicket();
      await this.$ticket.setData(newTicket);
      this.$ticket.post();
      this.onCompleted();
    } catch (e) {
      console.log(e);
      // Should add error handling
    }
    this.sending = false;
  }
  updateTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }

    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status_enquete: 'done',
    };

    let customTicket = '';
    switch (this.action) {
      case ENQUETE_ACTION.SEARCH_FAILED:
        customTicket = { status: 'searchFailed' };
        break;
      case ENQUETE_ACTION.SEARCH_NO_SCRIPT:
        customTicket = { status: 'searchNoScript' };
        break;
      case ENQUETE_ACTION.FEEDBACK_RESOLVED:
        customTicket = {
          status: 'answered',
          status_feedback: 'done',
          feedback: 'resolved',
        };
        break;
      case ENQUETE_ACTION.FEEDBACK_UNRESOLVED:
        customTicket = {
          status: 'answered',
          status_feedback: 'done',
          feedback: 'unresolved',
        };
        break;
      default:
        customTicket = { status: 'answered' };
    }
    const enquete = this.getEnqueteData();

    let newTicket = {
      ...commonTicket,
      ...customTicket,
      ...enquete,
      ...this.customizedTicket,
    };
    if (this.item) {
      newTicket.items = this.item.items;
    }
    return newTicket;
  }
  getEnqueteData() {
    const key = `enquete_${this.type}`;
    return {
      [key]: {
        [this.enquete.index]: {
          type: this.enquete.type,
          label: this.enquete.label,
          value: this.userMessage.trim(),
          index: Number(this.enquete.index),
        },
      },
    };
  }
  fetchEnquete() {
    this.enquete =
      this.type === ENQUETE_TYPE.RESOLVED
        ? Const.ENQUETE.RESOLVED
        : Const.ENQUETE.UNRESOLVED;
  }
  mounted() {
    this.fetchEnquete();
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';

$mainColor: #BF0000;
$grayColor: #686868;
$buttonHoverColor: #E80000;

.sai-enquete {
  width: 100%;

  .content {
    width: 100%;
    background-color: #fff;
    border: 1px solid $themaBorderColor;
    border-radius: 6px;

    &.input {
      padding: 18px 24px 24px 24px;
      .mobile & {
        padding: 18px;
      }
    }
    &.complete {
      width: 75%;
      margin: 0 auto;
      padding: 24px;
      .mobile & {
        width: calc(100% - 4px);
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;

      .title {
        line-height: 24px;
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        text-align: left;

        .mobile & {
          font-size: 14px;
        }
      }

      .close {
        flex-shrink: 0;
        position: relative;

        .mobile & {
          width: 0;
        }

        .icon {
          display: block;
          position: relative;
          right: -8px;

          .mobile & {
            top: -4px;
            right: 6px;
          }

          .img {
            width: 24px;
            height: 24px;
            background-image: url('../resource/net/close.svg');
            background-size: contain;
            background-repeat: no-repeat;

            .mobile & {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    .input {
      padding: 12px 0px 24px 0;

      .field {
        display: inline-block !important;
        width: 100% !important;
        padding: 2px;
        resize: none !important;
        box-shadow: none;
        -webkit-appearance: none !important;
        -moz-appearance: none !important;
        appearance: none !important;
        overflow-y: scroll !important;
        -ms-overflow-style: none !important;
        scrollbar-width: none;
        border: 1px solid $grayColor !important;
      }
      .field:focus {
        outline: none !important;
        box-shadow: none !important;
      }
      .field::placeholder {
        font-size: 16px !important;
        color: #bbb;
      }
    }
    .confirm {
      padding: 25px 5px;
      display: inline-block !important;
      width: 100% !important;
      height: 150px;
      text-align: left !important;
    }
    .submit {
      text-align: center;
      a {
        display: inline-block;
        width: 200px;
        padding: 12px 0;
        cursor: pointer;
        outline: none;
        background-color: $mainColor;
        border-radius: 5px;
        border: none;
        color: #fff;
        font-size: 16px;
        text-align: center;

        &:hover {
          background-color: $buttonHoverColor;
          .mobile & {
            background-color: $mainColor;
          }
        }

        .mobile & {
          width: 100%;
        }
      }
      .cancel {
        color: $themaFontColor;
        background-color: #ddd;
      }
    }
    .afterMessage {
      padding-bottom: 24px;
      font-size: 16px;
      &__text {
        text-align: center;
        white-space: pre-wrap;
      }
      .mobile & {
        font-size: 14px;
      }
    }
  }
}
</style>
