<template>
  <div
    id="sai-tag-app"
    :class="buildModeClass"
  >
    <div id="sai-tag-app-main">
      <router-view v-if="routing" />
      <FaqClientWrapper v-else />
    </div>
    <Multimodal />
  </div>
</template>

<script>
import Multimodal from '@/components/Multimodal';
import Const from './const';
import { extractQueryParams } from '@/common/uriUtil';

export default {
  name: 'App',
  components: { Multimodal },
  data: () => {
    return {
      routing: Const.ROUTING,
      buildModeClass: Const.MODE[process.env.BUILD_MODE].class,
    };
  },
  async created() {
    const origin = process.env.TYPE === 'both' ? 'page' : 'window';
    const pageType = Const.MODE[process.env.BUILD_MODE].ticketType;
    const { embed } = extractQueryParams(document.location.search);
    const eUrl = embed ? this.decodeEmbed(embed) : '';
    await this.$store.dispatch('Initialize');
    await this.$store.dispatch('product/init', {
      origin,
      pageType,
      embeddedUrl: eUrl,
    });
  },
  methods: {
    decodeEmbed: (embed) => {
      try {
        const url = decodeURIComponent(embed);
        return url;
      } catch (e) {
        return embed;
      }
    }
  }
};
</script>

<style lang="scss">
#sai-tag-app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;

  margin: 0;
  overflow: visible;

  input,
  textarea,
  select,
  button {
    font-size: 16px;
  }
  button {
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}
#sai-tag-app-main {
  display: flex;
}
</style>
