class ModalService {
  constructor() {
    document.addEventListener('keydown', e => {
      if (e.keyCode === 27) {
        this.closeModal();
      }
    });
  }
  register(modalComponent) {
    this.modalComponent = modalComponent;
  }
  openModal(modal) {
    this.modalComponent.openModal(modal);
  }
  closeModal() {
    this.modalComponent.closeModal();
  }
}

export const modalService = new ModalService();
