import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';

@Component({
  props: {},
})
export default class TagCategoryMixin extends Vue {
  async selectRegion(tagText) {
    const tags = await this.$store.dispatch(
      'tagSearch/tagifyTextExact',
      tagText
    );
    this.$emit('setShow', true);
    if (!this.$store.state.user.isIphone) {
      eventBus.$emit('selectNextTag');
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateTicket(tags);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async updateTicket(tags) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyActions = [
      { type: 'tag', value: 'popular' },
      { type: '', value: 'search' },
    ];
    await this.$store.dispatch(
      'ticket/addHistoryActionFaqChannels',
      historyActions
    );
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    } else {
      const tagUsedSet = tags.map(t => {
        const v = { id: t.id, text: t.text };
        if (t.displayText) {
          v.label = t.displayText;
        }
        return v;
      });
      await this.$store.dispatch('ticket/addTagUsedSets', tagUsedSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return newTicket;
  }
  async close(item) {
    if (!this.isBigCategory(item)) {
      await this.$store.dispatch('tagSearch/removeTagByName', item.text);
    }
    eventBus.$emit('setSelectedCategories', []);
  }
}
