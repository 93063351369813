<template>
  <div class="ColumnNavigation">
    <div class="navigationBreadWrapper">
      <Breadcrumbs :range="range" />
    </div>
    <div class="navigationContentWrapper">
      <div
        ref="navigationContent"
        :class="{ navigationContent: true, isRoot: routes.length <= 1 }"
        :style="{ left: navigationLeft + 'px' }"
      >
        <div
          class="page"
          v-for="(route, routeIndex) in routes"
          :key="routeIndex"
          :style="{
            left: getColumnLeft(routeIndex) + 'px',
            width: getColumnWidth(routeIndex) + 'px',
          }"
        >
          <component
            :is="route.componentName"
            :index="routeIndex"
            :currentValue="route"
            :prevValue="
              $store.state.navigation.routes[baseIndex + routeIndex - 1]
            "
            :nextValue="
              $store.state.navigation.routes[baseIndex + routeIndex + 1]
            "
            :currentLocal="locals[routeIndex]"
            :prevLocal="locals[routeIndex - 1]"
            :nextLocal="locals[routeIndex + 1]"
            :localActive="routeIndex === localIndex"
            :active="baseIndex + routeIndex === $store.state.navigation.index"
            :enableScroll="true"
          ></component>
        </div>
      </div>
    </div>
    <a
      v-show="canMovePositionBy(-1)"
      class="navigationButton navigationButtonLeft"
      @click.prevent="movePositionBy(-1)"
    >
      <img src="../resource/common/page_back.svg">
    </a>

    <a
      v-show="canMovePositionBy(1)"
      class="navigationButton navigationButtonRight"
      @click.prevent="movePositionBy(1)"
    >
      <img src="../resource/common/page_next.svg">
    </a>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import NavigationMixin from '@/mixins/NavigationMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';

@Component({})
export default class ColumnNavigation extends mixins(
  NavigationMixin,
  ProductTicketMixin
  ) {
  generateTicket(item, startTime, query, tagActiveSet) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return {
      ...commonTicket,
      ...ticketParams,
      ...this.customizedTicket
    };
  }
}
</script>
<style lang="scss" scoped>
.ColumnNavigation {
  position: relative;
  flex: 1;
  box-sizing: border-box;
  height: 100%;
}

.navigationBreadWrapper {
}

.navigationTitleWrapper {
  padding: 10px 20px;
  .navigationTitle {
    padding-bottom: 10px;
    vertical-align: middle;
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    border-bottom: solid 2px #BF0000;
  }
}

.navigationContentWrapper {
  position: absolute;
  top: 36px;
  bottom: 0;
  width: calc(100% - 24px);
  margin: 0 12px;
  overflow: hidden;
}
.navigationContent {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: left 0.5s;
  .sai-pc &.isRoot {
    background-repeat: no-repeat;
    background-position-x: right;
  }
}
.page {
  position: absolute;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
}
.navigationButton {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
  cursor: pointer;
  display: block;
  &.navigationButtonLeft {
    left: 0;
  }
  &.navigationButtonRight {
    right: 0;
  }
  img {
    width: 8px;
    height: 16px;
  }
}
</style>
