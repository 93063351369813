<template>
  <div v-if="result" class="result">
    <h2>
      {{ result.title }}
    </h2>
    <pre v-html="result.value"></pre>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    result: Object,
  },
})
export default class Result extends Vue {}
</script>
<style>
pre {
  white-space: pre-wrap;
  overflow-x: hidden;
  img {
    max-width: 100%;
  }
}
</style>
