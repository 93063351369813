<template>
  <div
    class="FaqClientWindow mobile"
    ref="faqClientTag"
    :style="{ height: windowHeight }"
  >
    <div class="content">
      <div class="search" ref="taggedInput" id="sai-tag-search-input">
        <div class="input_title">
          <div class="icon">
            <img src="../resource/common/search.svg" />
          </div>
          <div class="text">{{ searchInputTitle }}</div>
        </div>
        <TaggedInput
          :selectedTags="selectedTags.values()"
          :searchText="searchText"
          :inputEmpty="inputEmpty"
          :filteredItems="filteredItems"
          @onTagRemoved="removeSelectedTag"
          @onSearchTextChanged="setSearchText"
          @setShow="setTagSearchShow"
        />
      </div>
    </div>
    <div class="main">
      <div
        class="recommend"
        v-show="$store.state.tagSearch.show"
      >
        <div class="search" v-show="!inputEmpty">
          <div ref="tagList">
            <TagList
              :candidateTags="customizedCandidateTags"
              :tagListTop="tagListTop"
              :inputEmpty="inputEmpty"
              :searchText="searchText"
              @onSelectTag="addSelectedTag"
              @setShow="setTagSearchShow"
            />
          </div>
          <div class="heading">
            <div class="title">{{ recommendFaqTitle }}</div>
            <div class="search_result">
              <span class="count">{{ filteredItems.length }}</span>
              <span class="text">{{ resultSupportTextSp }}</span>
            </div>
          </div>
          <div class="autocomplete custom-list">
            <Autocomplete
              :filteredItems="filteredItems"
              @selected="selectItem"
              @setTagSearchShow="setTagSearchShow"
            />
          </div>
        </div>
        <div
          class="tagCategories"
          v-show="inputEmpty"
        >
          <div class="inner">
            <div class="content">
              <TagCategory
                :type="'sp'"
                :property="serviceTags.property"
                :title="serviceTagsTitle"
                :tags="serviceTags.tags"
                @tagifyText="tagifyTextExact"
                @setShow="setTagSearchShow"
              />
              <TagCategory
                :type="'sp'"
                :property="productTags.property"
                :title="productTagsTitle"
                :tags="productTags.tags"
                @tagifyText="tagifyTextExact"
                @setShow="setTagSearchShow"
              />
            </div>
          </div>
        </div>
      </div>
      <ColumnNavigation />
    </div>
    <div class="bottom_icon">
      <a href="https://saichat.jp/saisearch/" target="_blank" rel="noopener">
        <img src="../resource/powered_by_sciseed.png" />
      </a>
    </div>
    <Modal
      :withAnimation="true"
      :onModal="onModal"
      :onBackgroundClick="true"
      @toggleModal="toggleModal"
    >
      <Enquete
        :type="enquete.type"
        :action="enquete.action"
        :item="enquete.item"
        @toggleModal="toggleModal"
      />
    </Modal>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import ProductFaqClientTagMixin from '../mixins/ProductFaqClientTagMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';

@Component({})
export default class FaqClientWindow extends mixins(
  FaqClientTagMixin,
  ProductFaqClientTagMixin,
  ProductTicketMixin
) {
  searchInputTitle = Const.SEARCH_INPUT_TITLE;
  recommendFaqTitle = Const.RECOMMEND_FAQ_TITLE;

  scrollContainer = 'body';
  scrollTopElement = '.header';
  scrollFaqElement = '#sai-scenario';
  scrollAnswerElement = '#sai-scenario-answer';
  scrollSearchElement = '#sai-tag-search-input';
  autoScrollElement = '#sai-tag-search-input';
  iphoneTopElement = '.header';

  windowHeight = '100vh';
  bottomIconHeight = 32;
  headerHeight = 97;
  ifaHeaderHeight = 103;

  autoScroll() {
    if (this.$store.state.user.isIphone) {
      return;
    }
    if (!this.$store.state.tagSearch.show) {
      this.scrollTo(this.autoScrollElement, {
        container: this.scrollContainer,
        x: false,
        duration: 500,
      });
    }
  }
  setHeight() {
    const headerHeight = process.env.BUILD_MODE === 'net' ? this.headerHeight : this.ifaHeaderHeight;
    this.windowHeight = `${window.innerHeight - headerHeight}px`;
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };
    return { ...newTicket, ...this.customizedTicket };
  }
  created() {
    setTimeout(() => {
      this.setHeight();
    }, 0);
    window.addEventListener('resize', () => {
      this.setHeight();
    });
  }
}
</script>
<style lang="scss" scoped>
@import '../style/setting/variable';

.FaqClientWindow {
  font-family: "Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","Hiragino Sans","Noto Sans CJK JP",メイリオ,sans-serif !important;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 10px;
  z-index: 2;
}

.content {
  display: block;
  position: relative;

  .search {
    padding: 8px;

    .input_title {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .icon {
        display: inline-block;
        margin-right: 8px;

        img {
          width: 16px;
          height: 16px;
          vertical-align: middle;
        }
      }
      .text {
        display: inline-block;
        color: #333;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}

.recommend {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: $zIndex + 3;

  .search {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;

    .heading {
      display: flex;
      justify-content: space-between;
      border-bottom: solid 2px #BF0000;
      padding: 4px 0;
      margin: 0 8px;

      .title {
        font-size: 16px;
        font-weight: 700;
      }
      .search_result {
        color: #BF0000;
        .count {
          font-size: 16px;
        }
        .text {
          font-size: 10px;
        }
      }
    }
    .autocomplete {
      flex: 1 0;
      margin: 0 8px;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      background-color: #fff;
    }
  }

  .tagCategories {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #fff;

    .inner {
      position: relative;
      top: -12px;
      padding: 16px 8px 6px 8px;
      background-color: #fff;
      .content {
        padding: 0 8px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 6px rgba(51, 51, 51, 0.3);
      }
    }
  }
}

.main {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.resultContent {
  text-align: left;
  color: #333;
  .hasScenario & {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.bottom_icon {
  text-align: right;
  padding: 8px 8px 2px 0;
  a {
    display: inline-block;
    height: 20px;
    padding: 2px;
    cursor: pointer;
    img {
      height: 16px;
    }
  }
}
</style>
