const REG_CATEGORY = new RegExp(/[(（](.*?)[)）]/g);
const REG_BRACKETS = new RegExp(/[(（](.*?)[)）]/);
const REG_FAQ = new RegExp(/[【［[](FAQ.*?)[\]］】]/);

const existsCategory = text => {
  return text.match(REG_CATEGORY) || text.match(REG_FAQ);
};

const generateCategories = text => {
  if (!text) {
    return '';
  }
  let newText = '';
  const c = text.match(REG_CATEGORY);
  newText += c ? c[c.length - 1].match(REG_BRACKETS)[1] : '';
  const f = text.match(REG_FAQ);
  if (newText && f) {
    newText += ` / ${f[1]}`;
  } else if (f) {
    newText += f[1];
  }
  return newText;
};

const generateTitle = text => {
  const c = text.match(REG_CATEGORY);
  const newText = c ? text.replace(c[c.length - 1], '') : text;
  return newText.replace(REG_FAQ, '');
};

const generateFaq = text => {
  return text.match(REG_FAQ) ? text.match(REG_FAQ)[1] : '';
};

const existsFaq = text => {
  return text.match(REG_FAQ);
};

const generateCategory = text => {
  const c = text.match(REG_CATEGORY);
  return c ? c[c.length - 1].match(REG_BRACKETS)[1] : '';
}

const textParser = {
  existsCategory,
  existsFaq,
  generateCategories,
  generateTitle,
  generateFaq,
  generateCategory,
};

export default textParser;
