<template>
  <div>
    <div class="sai-tagged-input" :class="{ onFocus: show }">
      <div class="sai-tagged-input__field">
        <input
          type="text"
          size="1"
          :value="searchText"
          :placeholder="placeholderMessage"
          @input="customUpdateSearchText($event.target.value)"
          @focus="onFocus"
          @keyup="keyupHandler"
          @keydown="keydownHandler"
          @keypress="onKeypress"
          @compositionstart="onCompositionStart"
          @compositionend="onCompositionEnd"
          ref="searchInput"
        />
        <div class="sai-tagged-input__field__close" v-show="showIcon">
          <a
            class="icon"
            @click.prevent="resetSearchText"
          >
            <img src="../resource/common/close.svg" />
          </a>
        </div>
      </div>
      <div class="border" v-if="selectedTags.length > 0"></div>
      <div class="sai-tagged-input__tags" v-if="selectedTags.length > 0">
        <a
          class="sai-tagged-input__tags__tag"
          v-for="tag in selectedTags"
          :key="tag.id"
        >
          {{ '#' + ' ' + (tag.displayText || tag.text) }}
          <a
            class="sai-tagged-input__contents__tag__close"
            @click.prevent="removeTag(tag)"
            ><div class="img"></div>
          </a>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TaggedInputMixin from '@/mixins/TaggedInputMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';
import dataResource from '@/common/dataResource';
import talkScript from '@/common/talkScript';

@Component({})
export default class TaggedInput extends mixins(
  TaggedInputMixin,
  ProductTicketMixin
  ) {

  barWidth = 130;
  placeholderMessage = Const.INITIAL_INPUT_MESSAGE;

  async customUpdateSearchText(val) {
    if (val.toLowerCase().indexOf('faq') === -1) {
      this.updateSearchText(val);
    } else {
      await dataResource.ready({ resourceName: 'talkScript' });
      await talkScript.ready();
      let talkScripts = await dataResource.getList({
        resourceName: 'talkScript',
      });
      talkScripts = talkScripts
        .filter(s => {
          const match = s.text.match(/\[([^\[\]]*)\]/);
          if (match) {
            const tsId = match[1];
            if (tsId.toLowerCase().indexOf(val.toLowerCase()) !== -1)
              return true;
          }
          return false;
        })
        .slice(0, 10);
      this.$store.commit('tagSearch/setSearchText', val);
      this.$store.commit('tagSearch/setFilteredItems', talkScripts);
    }
  }
  async onFocus() {
    if (this.show) {
      return;
    }
    this.setShow(true);
    if (!this.$store.state.user.isIphone) {
      setTimeout(() => {
        eventBus.$emit('scrollToSearch');
      }, 300);
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  calcBarWidth(count) {
    const length = count >= 100 ? this.barWidth : this.barWidth * (count / 100);
    return length > 2 ? length : 2;
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return { ...newTicket, ...this.customizedTicket };
  }
  async keydownHandler(e) {
    this.keydownCode = e.keyCode;
    this.isComposing = e.isComposing;
  }
  async resetSearchText() {
    await this.updateSearchText('');

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  get showIcon() {
    return this.show && this.searchText;
  }
}
</script>
<style lang="scss" scoped>
@import '../style/settings';
@import '../style/component/searchBox';

$mainColor: #BF0000;
$subColor: #E80000;
$backgroundColor: #FFF0F0;
$grayColor: #686868;
$lightGrayColor: #d1d1d1;

.sai-tagged-input {
  position: relative;
  padding: 10px 12px 3px 7px;
  background-color: #fff;
  border: solid 1px $lightGrayColor;
  border-radius: 4px;
  margin: 0 -2px;

  .mobile & {
    padding: 9px 6px 4px 1px;
    border: solid 1px $grayColor;
  }

  &.onFocus {}

  &__field {
    display: flex;
    justify-content: space-between;

    input {
      display: inline-block;
      margin: 0px 0px 5px 5px;
      padding: 2px 5px;
      line-height: 24px;
      border: none;
      flex: 1;
      box-sizing: border-box;
      font-size: 14px;

      .mobile & {
        font-size: 16px;
      }

      &:focus {
        outline: 0;
      }
      &::placeholder {
        color: $grayColor;
      }
      /* IE */
      &:-ms-input-placeholder {
        color: $grayColor;
      }
      /* Edge */
      &::-ms-input-placeholder {
        color: $grayColor;
      }
    }
    input[type='text']::-ms-clear {
      display: none;
    }

    &__close {
      .icon {
        display: inline-block;
        padding: 5px 3px 5px 6px;
        cursor: pointer;

        .mobile & {
          padding: 6px 4px 5px 3px;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .border {
    height: 1px;
    border-top: solid 1px $lightGrayColor;
    margin: 0 4px 0 10px;
  }

  &__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 8px;
    margin-right: 28px;

    .mobile & {
      margin-right: 0px;
      margin-left: 5px;
    }

    &__tag {
      animation: tag-fade-in 0.2s;
      -ms-animation: tag-fade-in 0.2s;
      cursor: default;
      background-color: $backgroundColor;
      color: $mainColor;
      border-radius: 14px;
      padding: 4px 5px 4px 8px;
      margin: 0px 0 5px 5px;
      pointer-events: none;
      font-size: 12px;
      position: relative;

      .mobile & {
        background-color: $backgroundColor;
        font-size: 12px;
      }

      &:hover {
        color: $subColor;
        opacity: 0.7;
        -ms-opacity: 0.7;
        .mobile & {
          opacity: 1;
          -ms-opacity: 1;
          color: $mainColor;
        }

        a {
          .img {
            background-image: url('../resource/net/close_light.svg');
            .mobile & {
              background-image: url('../resource/net/close.svg');
            }
          }
        }
      }

      a {
        display: inline-block;
        padding: 0 4px 0 2px;
        cursor: pointer;
        pointer-events: auto;

        .img {
          display: inline-block;
          width: 9px;
          height: 9px;
          background-image: url('../resource/net/close.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      @keyframes tag-fade-in {
        0% {
          transform: scale(1);
          opacity: 0.5;
          -ms-opacity: 0;
          zoom: 1;
        }
        50% {
          transform: scale(1.05);
          opacity: 0.8;
          -ms-opacity: 0.8;
          zoom: 1;
        }
        100% {
          transform: scale(1);
          opacity: 1;
          -ms-opacity: 1;
          zoom: 1;
        }
      }
    }
  }
}
</style>
