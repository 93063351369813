<template>
  <div
    class="resolveEnquete"
    :class="{ mobile: $store.state.user.isSP }"
    v-show="currentValue && currentValue.viewType === 'result'"
  >
    <div class="resolveButtonArea">
      <div
        class="resolveButton"
        @click="showResolveButton = !showResolveButton"
      >
        解決した
      </div>
      <div
        class="resolveButton"
        @click="showResolveButton = !showResolveButton"
      >
        解決しなかった
      </div>
    </div>
    <div class="resolveTextOk resolveResultArea" v-show="showResolveButton">
      <p class="resolveResultArea__p">
        ご回答ありがとうございました。<br />
        今後のサービス改善の参考にさせていただきます。
      </p>
    </div>
    <div style="text-align:center;" v-show="showResolveButton">
      <a @click="scrollToTop" class="resolveButton">最初に戻る</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { scrollToTop } from '@/common/scrollUtil';
import { Watch } from 'vue-property-decorator';

@Component({ props: { currentValue: Object } })
export default class ResourceListFooter extends Vue {
  showResolveButton = false;
  scrollToTop(e) {
    e.preventDefault();
    scrollToTop(this.$el);
  }
  @Watch('currentValue')
  onChangeCurrentValue() {
    this.showResolveButton = false;
  }
}
</script>
<style lang="scss" scoped>
@import '~product/style/settings';

.resolveButtonArea {
  margin: 20px 0;
  text-align: center;
  .resolveButton {
    margin: 0 10px;
  }
}

.resolveButton {
  display: inline-block;
  width: 220px;
  margin: 0 10px;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
}

.enquete__message {
  margin-bottom: 20px;
  text-align: center;
}

.resolveResultArea {
  &__p {
    font-size: 18px;
    line-height: 1.8em;
  }
}

.resolveTextOk,
.resolveTextNg {
  margin: 30px 30px 6px;
  padding: 30px;
  font-weight: bold;
  font-size: 17px;
  color: #506a77;
  background: #e3e7e9;
  border-radius: 6px;
}

.resolveTextOk {
  font-size: 30px;
  text-align: center;
}

.resolveTextNg {
  h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h3 {
    margin: 10px 0 5px 13px;
  }
  a {
    color: #468de8;
    text-decoration: underline;
  }
  & > div,
  & > a {
    margin-left: 30px;
  }
}

.mobile {
  .buttonWrapper {
    display: flex;
    & > .resolveButton {
      width: 50%;
      &.disabled {
        display: none;
      }
    }
  }
}
</style>
