<template>
  <div>
    <slot @updateScrollGuide="updateScrollGuide"></slot>
    <div class="scrollGuide" v-show="showGuide">
      <ScrollGuideContent />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ScrollGuide extends Vue {
  showGuide = false;
  scrollContainer = null;
  mounted() {
    this.scrollContainer = this.$el.querySelector('.scrollY');
    if (this.scrollContainer) {
      this.scrollContainer.addEventListener('scroll', this.onScroll);
      setTimeout(() => {
        this.onScroll(null, true);
      }, 100);
    }
  }
  unmounted() {
    this.scrollContainer &&
      this.scrollContainer.removeEventListener('scroll', this.onScroll);
  }
  updateScrollGuide() {
    this.onScroll(null, true);
  }
  onScroll(event, forceSet) {
    if (!this.scrollContainer) {
      return;
    }
    const wrapper = this.$el.querySelector('.scrollWrapper');
    if (
      this.scrollContainer.scrollTop > 30 || // 動かす
      (this.scrollContainer.scrollTop === 0 &&
        wrapper.clientHeight < this.scrollContainer.clientHeight) ||
      this.scrollContainer.scrollHeight <
        this.scrollContainer.clientHeight + this.scrollContainer.scrollTop + 15 // 最後までスクロール
    ) {
      this.showGuide = false;
    } else if (forceSet || this.scrollContainer.scrollTop === 0) {
      this.showGuide = true;
    }
    // console.log(
    //   this.showGuide,
    //   forceSet,
    //   this.scrollContainer.scrollTop,
    //   this.scrollContainer.scrollHeight,
    //   this.scrollContainer.clientHeight + this.scrollContainer.scrollTop,
    //   { e: this.scrollContainer }
    // );
  }
}
</script>

<style lang="scss" scoped>
.scrollGuide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  pointer-events: none;
  .pc & {
    margin-right: auto;
    margin-left: auto;
  }
  .pc .autocompleteWrapper & {
    height: 536px;
  }
  .mobile {
    margin: auto;
  }
}
</style>
