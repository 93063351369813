<template>
  <transition :name="withAnimation ? 'modal' : ''">
    <div
      class="sai-modal"
      v-if="onModal"
      @click.self="closeModal"
    >
      <div class="inner">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
@Component({
  props: {
    withAnimation: Boolean,
    onModal: Boolean,
    onBackgroundClick: Boolean,
  },
})
export default class Modal extends Vue {
  closeModal() {
    if (this.onBackgroundClick) {
      this.$emit('toggleModal', false);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.1s;
  .sai-modal {
    transition: opacity 0.1s;
  }
}
.modal-leave-active {
  transition: opacity 0.1s ease 0.1s;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
  .sai-modal {
    opacity: 0;
  }
}
.sai-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(28, 28, 28, 0.7);
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  align-items: center;
  z-index: $zIndex + 10;
  .inner {
    position: relative;
    width: 60%;
    @media screen and (max-width: 800px) {
      width: 95%;
    }
  }
}
</style>
