<template>
  <div>
    <slot v-bind:item="item"></slot>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import dataResource from '@/common/dataResource';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';

@Component({
  props: {
    currentValue: Object,
    active: Boolean,
  },
})
export default class ResourceList extends Vue {
  @AsyncComputed()
  async item() {
    const item = await dataResource.getItem(this.currentValue);
    return item;
  }
}
</script>
<style lang="scss" scoped></style>
