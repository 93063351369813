<template>
  <div class="sai-list-content">
    <ul class="sai-list-content__lists">
      <li
        class="sai-list-content__lists__item"
        v-for="(item, index) in list"
        v-if="item"
        :key="item.talkScriptId"
        :style="{ animationDelay: `${index < 20 ? 0.05 * (index + 1) : 0}s` }"
        @click="selectItem(item)"
      >
        <i class="sai-list-content__lists__item__top_icon"></i>
        <div class="sai-list-content__lists__item__wrapper">
          <div class="sai-list-content__lists__item__content">
            <div class="sai-list-content__lists__item__content__ancesters">
              <span
                class="sai-list-content__lists__item__content__ancesters__ancester"
                v-for="(ancester, index) in item.ancesters"
                :key="index"
              >
                {{ ancester.text }}
                <span v-if="index < item.ancesters.length - 1">＞</span>
              </span>
            </div>
            <div class="sai-list-content__lists__item__content__title">
              <div class="sai-list-content__lists__item__content__title__text">
                {{ generateTitle(item.text) }}
              </div>
            </div>
          </div>
          <div
            class="sai-list-content__lists__item__category"
            v-if="existsFaq(item.text)"
          >
            <div class="sai-list-content__lists__item__category__text">
              {{ generateFaq(item.text) }}
            </div>
          </div>
        </div>
        <div class="sai-list-content__lists__item__wrapper sp">
          <div class="sai-list-content__lists__item__content">
            <div class="sai-list-content__lists__item__content__ancesters">
              <span
                class="sai-list-content__lists__item__content__ancesters__ancester"
                v-for="(ancester, index) in item.ancesters"
                :key="index"
              >
                {{ ancester.text }}
                <span v-if="index < item.ancesters.length - 1">&gt;</span>
              </span>
            </div>
            <div
              class="sai-list-content__lists__item__category"
              v-if="existsFaq(item.text)"
            >
              <div class="sai-list-content__lists__item__category__text">
                {{ generateFaq(item.text) }}
              </div>
            </div>
          </div>
          <div class="sai-list-content__lists__item__content__title">
            <div class="sai-list-content__lists__item__content__title__text">
              {{ generateTitle(item.text) }}
            </div>
          </div>
        </div>
        <div class="sai-list-content__lists__item__icon">
          <div class="img"></div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ListContentMixin from '@/mixins/ListContentMixin';
import textParser from '../libs/textParser';

@Component({})
export default class ListContent extends mixins(ListContentMixin) {
  generateTitle(text) {
    return textParser.generateTitle(text);
  }
  existsFaq(text) {
    return textParser.existsFaq(text);
  }
  generateFaq(text) {
    return textParser.generateFaq(text);
  }
}
</script>

<style lang="scss" scoped>

.sai-list-content {
  background-color: #fff;

  .custom-list & {
    height: calc(100% - 42px);
    overflow-y: scroll;
    padding-right: 12px;

    &::-webkit-scrollbar {
      display: block;
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d1d1d1;
      border-radius: 4px;
    }

    .mobile & {
      border-radius: 0;
      height: 100%;
      padding-right: 0;
      border-top: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__lists {

    .mobile & {
      font-size: 14px;
    }

    &__item {
      display: flex;
      position: relative;
      align-items: center;
      padding: 16px 0 0 0;
      cursor: pointer;
      .mobile {
        border-bottom: 1px solid #d1d1d1;
      }

      .chrome & {
        animation: list-fade-in 0.15s ease forwards;
        transform: translate(0, 0);

        @keyframes list-fade-in {
          0% {
            transform: translate(40px, 0);
          }
          100% {
            transform: translate(0, 0);
          }
        }
      }

      &__top_icon {
        display: block;
        position: relative;
        width: 24px;
        height: 24px;
        top: 0px;
        background-image: url(../resource/net/list_q.svg);
        background-size: contain;
        background-repeat: no-repeat;

        .custom-list & {
          top: 0;
        }
      }

      .custom-list & {
        &:last-child {
          padding: 16px 0;
        }
        animation: none;
        .mobile & {
          transition: 0s;
          padding: 9px 0;
          border-bottom: 1px solid #d1d1d1;
        }
        &:hover {
          .mobile & {
            color: #333;
            transform: translateX(0);
          }
        }
      }

      &__wrapper {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0 0 12px;
        .mobile & {
          display: none;
          flex-wrap: nowrap;
        }

        &.sp {
          display: none;
          .mobile & {
            display: block;
            flex-wrap: nowrap;
          }
        }
      }

      &__content {
        flex: 1 1;
        padding-right: 18px;
        .mobile & {
          display: flex;
          justify-content: space-between;
          padding-right: 0px;
          width: 100%;
        }

        &__title {
          &__text {
            font-weight: 700;
            color: #333;
            font-size: 16px;

            .custom-list & {
              .mobile & {
                font-size: 14px;
                font-weight: normal;
                padding: 4px 0 0 0;
              }
            }

            span {
              font-weight: 600;
              font-size: 14px;
              display: inline-block;
              .mobile & {
                font-size: 12px;
              }
            }
            .sai-list-content__lists__item:hover & {
              transform: translateX(10px);
              transition: 0.08s;
              color: #E80000;
              .mobile & {
                color: #333;
                transition: 0s;
                transform: translateX(0);
              }
            }
          }
        }
        &__ancesters {
          flex: 1 1;
          display: block;
          color: #686868;
          font-size: 12px;

          .mobile & {
            font-size: 10px;
          }

          .sai-list-content__lists__item:hover & {
            transform: translateX(10px);
            transition: 0.08s;
            color: #E80000;
            .mobile & {
              color: #686868;
              transition: 0s;
              transform: translateX(0);
            }
          }
        }
      }
      &__category {
        padding: 0px 10px 0 0;
        .mobile & {
          display: none;
        }
        &__text {
          text-align: right;
          font-size: 12px;
          color: #686868;

          .sai-list-content__lists__item:hover & {
            color: #E80000;
          }
        }
      }
      &__icon {
        display: inline-block;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        text-align: center;

        .mobile & {
          text-align: right;
        }

        .img {
          display: inline-block;
          margin: 5px auto;
          width: 8px;
          height: 16px;
          background-image: url(../resource/common/next_gray.svg);
          background-size: contain;
          background-repeat: no-repeat;

          .mobile & {
            background-image: url(../resource/net/next.svg);
          }
        }

        .sai-list-content__lists__item:hover & {
          background-color: #E80000;
          .img {
            background-image: url(../resource/common/next_white.svg);
            width: 7px;
            height: 15px;
          }
          .mobile & {
            background-color: #fff;
            .img {
              width: 8px;
              height: 16px;
              background-image: url(../resource/net/next.svg);
            }
          }
        }
      }
    }
  }
}
</style>
