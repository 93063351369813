<template>
  <div class="FaqClientWrapper">
    <Loading :isLoading="!isReady">
      <FaqClientPage v-if="componentType === 'pc'" ref="faqClient" />
      <FaqClientWindow v-if="componentType === 'sp'" ref="faqClient" />
      <FaqClientTag v-if="componentType === 'tag'" ref="faqClient" />
      <FaqClientMultiple v-if="componentType === 'both'" ref="faqClient" />
    </Loading>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import isEqual from 'lodash/isEqual';

import { Watch } from 'vue-property-decorator';
// import * as scrollUtil from '@/common/scrollUtil';
import { fromRoute, toRoute } from '@/common/resourceNavigationUtil';

const Const = require(`../../products/${process.env.PRODUCT_ID}/const/index`);

@Component({})
export default class FaqClientWrapper extends Vue {
  componentType = process.env.TYPE;
  nextRouteParams = null;
  created() {
    if (!Const.ROUTING) {
      return;
    }
    this.$router.afterEach((route, from) => {
      this.$store.dispatch('history/add', route.params);
    });
  }
  // @Watch('$route.meta.type', { immediate: true })
  // onIsMobileChanged(type) {
  //   this.componentType = type;
  // }
  @Watch('$route.params', { immediate: true })
  async onRouteParamsChanged(params, oldParams) {
    if (!Const.ROUTING) {
      this.autoScroll();
      return;
    }
    if (isEqual(this.nextRouteParams, params)) {
      console.log('cancel update params');
      return;
    }
    console.log(
      "@Watch('$route.params')",
      params,
      oldParams,
      this.nextRouteParams
    );
    const route = await fromRoute(params);
    const routes = [];
    for (let current = route.talkScript; current; current = current.parent) {
      routes.unshift(current);
    }
    routes.push.apply(routes, route.scenarios);
    this.$store.commit('navigation/setRoutes', routes);
    this.autoScroll();
  }
  @Watch('$store.state.navigation.routes')
  @Watch('$store.state.navigation.index')
  onNavigationChanged(state, oldState) {
    if (!Const.ROUTING) {
      this.autoScroll();
      return;
    }
    const params = toRoute(
      this.$store.getters['navigation/getEfficientRoutes']
    );
    if (isEqual(this.$route.params, params)) {
      console.log('cancel route update');
      return;
    }
    console.log(
      "@Watch('$store.state.navigation')",
      this.$route.params,
      params
    );
    this.nextRouteParams = params;
    this.$router.push({
      params: params,
    });
    this.autoScroll();
  }
  autoScroll() {
    if (
      'faqClient' in this.$refs &&
      'autoScrollElement' in this.$refs.faqClient
    ) {
      setTimeout(() => {
        this.$refs.faqClient.autoScroll();
      }, 0);
    }
  }
  get isReady() {
    return this.$store.getters['isReady'];
  }
}
</script>

<style lang="scss" scoped>
// .FaqClientWrapper {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   font-family: "Futura Koyu", sans-serif;
// }
</style>
