<template>
  <div class="modalSample ">
    <div class="modalHeader">
      <div class="modalTitle">これがタイトル {{ message }}</div>
      <div class="modalCloseButton">
        <button class="fa fa-times" @click="$emit('closeModal', 'X')"></button>
      </div>
    </div>
    <button @click="openModal">Next</button>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  // components: {},
  props: {
    message: String,
  },
})
export default class ModalSample extends Vue {
  openModal() {
    this.$emit('openModal', {
      component: this.constructor,
      message: 'dammy',
    });
  }
}
</script>

<style lang="scss" scoped>
.modalHeader {
  border-bottom: 1px solid #ccc;
  line-height: 24px;
  padding: 16px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  > * {
    flex: 1;
    align-self: center;
  }
  > .modalCloseButton {
    flex: 0;
    > button {
      font-size: 24px;
    }
  }
}
</style>
