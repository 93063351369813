import Vue from 'vue';
import Component from 'vue-class-component';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    candidateTags: Array,
    tagListTop: Number,
    inputEmpty: Boolean,
    searchText: String,
  },
})
export default class TagListMixin extends Vue {
  get selectedTagIds() {
    return this.$store.state.tagSearch.selectedTags.values().map(t => {
      return t.id;
    });
  }
  get filteredCandidateTags() {
    if (
      this.$store.state.tagSearch.filteredItems.length === 1 &&
      !this.inputEmpty
    ) {
      return [];
    }
    if (this.selectedTagIds.length === 0) {
      return this.candidateTags;
    }
    return this.candidateTags.filter(t => {
      return this.selectedTagIds.indexOf(t.tag.id) === -1;
    });
  }
  convertTagText(candidate) {
    if (candidate.displayText) {
      const dt = candidate.displayText.toLowerCase();
      if (candidate.tag.text.toLowerCase().indexOf(dt) === -1) {
        return candidate.displayText;
      }
    }
    return candidate.tag.text;
  }
  async selectTag(candidate) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    const displayText = this.convertTagText(candidate);
    const selectedTag = Object.assign(candidate.tag, { displayText });
    const exTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const exText = this.$store.state.tagSearch.searchText;

    await this.$emit('onSelectTag', selectedTag);

    if (!this.$store.state.user.isIphone) {
      eventBus.$emit('selectNextTag');
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }

    const newTicket = await this.updateTicket(selectedTag, exTags, exText);
    await this.$ticket.setData(newTicket);
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      setTimeout(() => {
        this.$ticket.post();
      }, 200);
      return;
    }
    this.$ticket.post();
  }
  async updateTicket(tag, exTags, exText) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const categoryTags = this.$store.getters['tagSearch/selectedCategoryTags'];

    let historyActions = [];
    if (exTags.length === 0 && categoryTags.length === 0) {
      historyActions = [
        { type: 'tag', value: exText ? 'initial' : 'popular' },
        { type: '', value: 'search' },
      ];
    } else if (exTags.length === 0 && categoryTags.length > 0) {
      historyActions = [{ type: 'tag', value: 'initial' }];
    } else if (exTags.length > 0 && categoryTags.length === 0) {
      historyActions = [{ type: '', value: 'search' }];
    }
    if (historyActions.length > 0) {
      await this.$store.dispatch(
        'ticket/addHistoryActionFaqChannels',
        historyActions
      );
    }
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    } else {
      let tagUsedSet = { id: tag.id, text: tag.text };
      if (tag.displayText) {
        tagUsedSet.label = tag.displayText;
      }
      this.$store.commit('ticket/addTagUsedSet', tagUsedSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return newTicket;
  }
  handleTagList(e) {
    if (!this.$refs.tagListInner.contains(e.target)) {
      this.setShow(false);
    }
  }
  setShow(boolean) {
    this.$emit('setShow', boolean);
  }
  closeList() {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    this.setShow(false);
  }
  filterCandidateTags(tags) {
    const currentTags = this.$store.state.tagSearch.selectedTags.values();
    return tags.filter(t => {
      return currentTags.indexOf(t) === -1;
    });
  }
}
