<template>
  <div class="sai-tag-category" :class="property">
    <div class="search_title">
      <span
        class="search_title__text"
      >{{ title }}</span>
    </div>
    <div class="search_btns cf">
      <div
        class="search_btn"
        v-for="(t, idx) in tags"
        :key="categoryTagId(idx)"
      >
        <ImageTag
          :text="t.text"
          :displayText="t.displayText"
          :icon="t.icon"
          :type="type"
          @selectTag="selectRegion(t.text)"
        ></ImageTag>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';
import TagCategoryMixin from '@/mixins/TagCategoryMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';

@Component({
  props: {
    type: String,
    property: String,
    title: String,
    tags: Array,
  },
})
export default class TagCategory extends mixins(
  TagCategoryMixin,
  ProductTicketMixin
  ) {

  categoryTagId(idx) {
    return `${this.property}-${idx}`;
  }
  async selectRegion(tagText) {
    const tags = await this.$store.dispatch(
      'tagSearch/tagifyTextExact',
      tagText
    );
    this.$emit('setShow', true);
    if (!this.$store.state.user.isIphone) {
      eventBus.$emit('selectNextTag');
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = await this.updateTicket(tags);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async updateTicket(tags) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyActions = [
      { type: 'tag', value: 'customizedPopular' },
      { type: '', value: 'search' },
    ];
    await this.$store.dispatch(
      'ticket/addHistoryActionFaqChannels',
      historyActions
    );
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    } else {
      const tagUsedSet = tags.map(t => {
        const v = { id: t.id, text: t.text };
        if (t.displayText) {
          v.label = t.displayText;
        }
        return v;
      });
      await this.$store.dispatch('ticket/addTagUsedSets', tagUsedSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }

  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return { ...newTicket, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
$mainColor: #BF0000;
$hoverColor: #E80000;

.sai-tag-category {
  padding-top: 12px;
  width: 100%;

  &.product {
    padding-top: 24px;
  }

  .mobile & {
    padding-top: 8px;
  }

  .search_title {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &__text {
      line-height: 24px;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .search_btns {
    display: flex;
    flex-wrap: wrap;

    .search_btn {
      display: flex;
      align-items: center;
      margin: 0 12px 10px 0;
    }

    .mobile & {
      justify-content: space-between;
      .search_btn {
        width: calc(50% - 4px);
        min-height: 56px;
        margin: 0 0 8px 0;
      }
    }
  }
}
</style>
