import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {},
})
export default class ProductTicketMixin extends Vue {
  get customizedTicket() {
    return {
      origin: this.$store.state.product.origin,
      page_type: this.$store.state.product.pageType,
      embedded_url: this.$store.state.product.embeddedUrl,
      initial_tag_active_set: this.$store.state.ticket.initialTagActiveSet,
      initial_query: this.$store.state.ticket.initialQuery,
      initial_parent_category: this.$store.state.ticket.initialParentCategory,
      initial_child_category: this.$store.state.ticket.initialChildCategory,
    }
  }
}
