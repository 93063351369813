<template>
  <div
    class="FaqClientPage sai-pc"
    :class="{ chrome: isChrome() }"
    ref="faqClientTag"
  >
    <div class="sai-wrap" id="sai-top">
      <div class="sai-contentBody">
        <div class="sai-category" ref="category">
          <div class="sai-category__list">
            <ResourceTree
              :currentValue="{ resourceName: 'talkScript', talkScriptId: '#' }"
              :isRoot="true"
              :condition="item => item.resourceName === 'talkScript'"
              :showResult="showResult"
              :selectedCategories="selectedCategories"
              @setTagSearchShow="setTagSearchShow(true)"
            />
          </div>
        </div>
        <div class="sai-content" id="sai-tag-search-input">
          <div class="sai-content__wrap">
            <div class="sai-content__wrap__search">
              <div class="sai-input" :class="{ onFocus: show }">
                <div class="sai-input__close" v-if="show">
                  <a
                    class="icon"
                    @click.prevent="setTagSearchShow(false)"
                  >
                    <img src="../resource/common/close.svg" />
                  </a>
                </div>
                <div class="sai-input__title">
                  <div class="icon">
                    <img src="../resource/common/search.svg" />
                  </div>
                  <div class="text">
                    {{ searchInputTitle }}
                  </div>
                </div>
                <div class="sai-input__field">
                  <div ref="taggedInput">
                    <TaggedInput
                      :selectedTags="selectedTags.values()"
                      :searchText="searchText"
                      :filteredItems="filteredItems"
                      @onTagRemoved="removeSelectedTag"
                      @onSearchTextChanged="setSearchText"
                      @setShow="setTagSearchShow"
                    />
                  </div>
                </div>
              </div>
              <transition name="fade">
                <div
                  class="sai-recommend"
                  v-show="isAutocompleteShown"
                  :style="{
                    height: inputEmpty ? 'auto' : `calc(100vh - 74px - ${tagListTop}px)`,
                  }"
                >
                  <div ref="tagList">
                    <TagList
                      :candidateTags="customizedCandidateTags"
                      :tagListTop="tagListTop"
                      :searchText="searchText"
                      :inputEmpty="inputEmpty"
                      @onSelectTag="addSelectedTag"
                      @setShow="setTagSearchShow"
                    />
                  </div>
                  <div
                    :style="{ height: `calc(100% - ${customListHeight()}px)` }"
                  >
                    <div
                      class="sai-tag-categories"
                      v-show="inputEmpty"
                    >
                      <TagCategory
                        :type="'pc'"
                        :property="serviceTags.property"
                        :title="serviceTagsTitle"
                        :tags="serviceTags.tags"
                        @tagifyText="tagifyTextExact"
                        @setShow="setTagSearchShow"
                      />
                      <TagCategory
                        :type="'pc'"
                        :property="productTags.property"
                        :title="productTagsTitle"
                        :tags="productTags.tags"
                        @tagifyText="tagifyTextExact"
                        @setShow="setTagSearchShow"
                      />
                    </div>
                    <div
                      class="sai-searched-list custom-list"
                      v-show="!inputEmpty"
                    >
                      <div class="sai-searched-list__heading">
                        <div class="title">{{ recommendFaqTitle }}</div>
                        <div class="search_result">
                          <span class="count">{{ filteredItems.length }}</span>
                          <span class="text">{{ resultSupportTextPc }}</span>
                        </div>
                      </div>
                      <CategorySearch
                        :filteredItems="filteredItems"
                        @setTagSearchShow="setTagSearchShow"
                      />
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div
              class="sai-content__wrap__clear"
              id="sai-faq-top"
              ref="clearElements"
              v-show="showResult"
            >
              <a
                v-show="!inputEmpty"
                class="sai-content__wrap__clear__button"
                @click.prevent="backToSearch()"
              >
                <div class="sai-content__wrap__clear__button__icon chevron">
                  <div class="img"></div>
                </div>
                <span>{{ backToSearchButtonText }}</span>
              </a>
              <a
                class="sai-content__wrap__clear__button"
                @click.prevent="customClear()"
              >
                <div class="sai-content__wrap__clear__button__icon redo">
                  <div class="img"></div>
                </div>
                <span>{{ clearButtonTitle }}</span>
              </a>
            </div>
            <div
              class="sai-content__wrap__scenario"
              id="sai-scenario"
              ref="scenario"
              v-show="showResult"
            >
              <div class="sai-content__wrap__scenario__inner">
                <section
                  class="sai-content__wrap__scenario__inner__section question"
                  :class="{
                    active:
                      sections.talkScript.end === $store.state.navigation.index,
                    hasScenario: sections.scenario.length,
                  }"
                >
                  <div
                    class="sai-content__wrap__scenario__inner__section__head"
                    v-if="
                      $store.state.navigation.routes[sections.talkScript.end]
                    "
                  >
                    <div class="scenario_wrap">
                      <i
                        class="sai-content__wrap__scenario__inner__section__head__icon"
                      ></i>
                      <div
                        class="sai-content__wrap__scenario__inner__section__head__title"
                      >
                        <div class="ancestors">
                          <span
                            v-for="(ancester, index) in scriptAncesters"
                            :key="index"
                          >
                            {{ ancester.text }}
                            <span v-if="index < scriptAncesters.length - 1"
                              >＞</span
                            >
                          </span>
                        </div>
                        <div class="question">
                          {{ generateTitle(currentFaq.text) }}
                        </div>
                      </div>
                      <div
                        class="sai-content__wrap__scenario__inner__section__head__faq"
                        v-if="existsFaq(currentFaq.text)"
                      >
                        <div class="text">
                          <span>{{ generateFaq(currentFaq.text) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="sai-content__wrap__scenario__inner__section__content"
                  >
                    <VerticalNavigation
                      v-if="sections.scenario.length > 0"
                      :range="sections.scenario"
                    />
                    <section
                      class="sai-content__wrap__scenario__inner__section answer"
                      id="sai-scenario-answer"
                      v-if="sections.result.length"
                    >
                      <div
                        class="sai-content__wrap__scenario__inner__section__head"
                      >
                        <div class="scenario_wrap">
                          <i
                            class="sai-content__wrap__scenario__inner__section__head__icon"
                          ></i>
                          <div
                            class="sai-content__wrap__scenario__inner__section__head__title"
                          >
                            <div class="question">
                              {{ faqAnswerTitle }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="sai-content__wrap__scenario__inner__section__content"
                      >
                        <VerticalNavigation
                          v-if="sections.result.length > 0"
                          :range="sections.result"
                        />
                      </div>
                    </section>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="sai-content__wrap__faq"
              v-show="!showResult"
              ref="listWrapper"
            >
              <div class="sai-content__wrap__faq__content">
                <div class="sai-content__wrap__faq__content__header">
                  <div class="sai-content__wrap__faq__content__header__title">
                    {{ frequentTitle }}
                  </div>
                </div>
                <Frequent @setTagSearchShow="setTagSearchShow" />
              </div>
              <div class="sai-content__wrap__faq__content">
                <div class="sai-content__wrap__faq__content__header">
                  <div class="sai-content__wrap__faq__content__header__title">
                    {{ historyTitle }}
                  </div>
                </div>
                <History @setTagSearchShow="setTagSearchShow" />
              </div>
            </div>
          </div>
          <div class="bottom_icon">
            <a href="https://saichat.jp/saisearch/" target="_blank" rel="noopener">
              <img src="../resource/powered_by_sciseed.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        class="sai-tag-search-modal"
        ref="modal"
        v-show="$store.state.tagSearch.show"
      ></div>
    </transition>
    <Modal
      :withAnimation="true"
      :onModal="onModal"
      :onBackgroundClick="true"
      @toggleModal="toggleModal"
    >
      <Enquete
        :type="enquete.type"
        :action="enquete.action"
        :item="enquete.item"
        @toggleModal="toggleModal"
      />
    </Modal>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import ProductFaqClientTagMixin from '../mixins/ProductFaqClientTagMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import { eventBus } from '@/eventBus';
import Const from '../const/index';
import textParser from '../libs/textParser';

@Component({})
export default class FaqClientPage extends mixins(
  FaqClientTagMixin,
  ProductFaqClientTagMixin,
  ProductTicketMixin
) {

  searchInputTitle = Const.SEARCH_INPUT_TITLE;
  frequentTitle = Const.FREQUENT_TITLE;
  historyTitle = Const.HISTORY_TITLE;
  faqAnswerTitle = Const.FAQ_ANSWER_TITLE;
  backToSearchButtonText = Const.BACK_TO_SEARCH_BUTTON_TEXT;
  clearButtonTitle = Const.CLEAR_BUTTON_TEXT;
  recommendFaqTitle = Const.RECOMMEND_FAQ_TITLE;

  scrollContainer = 'body';
  scrollTopElement = '.header';
  scrollSearchElement = '#sai-tag-search-input';
  scrollFaqElement = '#sai-faq-top';
  scrollAnswerElement = '#sai-scenario-answer';

  generateTitle(text) {
    return textParser.generateTitle(text);
  }
  existsFaq(text) {
    return textParser.existsFaq(text);
  }
  generateFaq(text) {
    return textParser.generateFaq(text);
  }
  get currentFaq() {
    return this.$store.state.navigation.routes[this.sections.talkScript.end];
  }
  get scriptAncesters() {
    return this.$store.state.navigation.routes[this.sections.talkScript.end]
      .ancesters;
  }

  // TODO: ブラウザ判定ライブラリ等を導入する
  isChrome() {
    return (
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    );
  }
  customListHeight() {
    return this.tagListBottom - this.tagListTop;
  }
  async customClear() {
    await eventBus.$emit('reset');
    setTimeout(() => {
      eventBus.$emit('scrollToTop');
    }, 0);
    if (this.$store.state.ticket.resetFlag) {
      this.resetTicket();
      return;
    }
    const newTicket = this.updateTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async backToSearch() {
    this.setTagSearchShow(true);
    setTimeout(() => {
      eventBus.$emit('scrollToSearch');
    }, 500);

    if (this.$store.state.ticket.resetFlag) {
      this.resetTicket();
      return;
    }

    const newTicket = this.updateTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  handleTagList(e) {
    if (this.$refs.modal && this.$refs.modal.contains(e.target)) {
      this.setTagSearchShow(false);
    }
  }
  isParentCategory(item) {
    return item.parent && item.parent.talkScriptId === '#';
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };
    return { ...newTicket, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
@import '../style/component/FaqClientPc';
</style>
