<template>
  <div
    class="sai-resourceTree"
    :class="
      isBigCategory(currentValue) && isSelected(currentValue) && 'addBottom'
    "
  >
    <div
      v-if="!isRoot && currentValue && currentValue.talkScriptType === 'node'"
      class="content"
      @click.prevent="toggleItems(currentValue)"
      :class="[
        isBigCategory(currentValue) ? 'withItems' : 'withoutItems',
        isActive(currentValue) && 'active',
        isSelected(currentValue) && 'selected',
      ]"
    >
      <span class="title">
        {{ currentValue.text }}
      </span>
    </div>

    <div
      v-if="isRoot || isActive(currentValue)"
      ref="items"
      :class="[
        'items',
        isActive(currentValue) && 'active',
        isSelected(currentValue) && 'selected',
      ]"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="['item', `talkScriptType-${item.talkScriptType}`]"
      >
        <ResourceTree
          :currentValue="item"
          :condition="condition"
          :isRoot="false"
          :showResult="showResult"
          :selectedCategories="selectedCategories"
          @setTagSearchShow="setTagSearchShow"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ResourceTreeMixin from '@/mixins/ResourceTreeMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import { eventBus } from '@/eventBus';

@Component({})
export default class ResourceTree extends mixins(
  ResourceTreeMixin,
  ProductTicketMixin
  ) {

  async open(item) {
    if (!this.isBigCategory(item)) {
      await this.$store.dispatch('tagSearch/resetInput');
      await this.$store.dispatch('tagSearch/addSelectedTag', {
        text: item.text,
        displayText: `${item.parent.text} ＞ ${item.text}`,
        type: 'node',
        id: item.talkScriptId,
      });
      await this.setTagSearchShow();
      setTimeout(() => {
        eventBus.$emit('scrollToSearch');
      }, 0);
    }
    const categories = this.isBigCategory(item) ? [item] : [item.parent, item];
    eventBus.$emit('setSelectedCategories', categories);

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateTicket(item);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: 'open',
      items: item.items,
      ...this.customizedTicket
    };

    return newTicket;
  }
}
</script>

<style lang="scss" scoped>
$borderWidth: 2px;
$mainColor: #BF0000;

.sai-resourceTree {
  position: relative;

  &.addBottom {
    margin-bottom: 12px;
  }

  .content {
    display: inline-block;
    width: 100%;
    padding: 11px 10px;
    border-left: solid $borderWidth transparent;
    cursor: pointer;

    &.withItems {
      &.active,
      &.selected,
      &:hover {
        background-color: #fff;
        border-left: solid $borderWidth $mainColor;
      }
      &:before,
      &.selected:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 14px;
        width: 16px;
        height: 16px;
        background-size: contain;
      }
      &:before {
        background-image: url(../resource/net/category.svg);
      }
      &.active:before,
      &.selected:before {
        transform: scale(1, -1);
      }
    }

    &.withoutItems {
      border-left: none;
      padding: 11px 10px 11px 16px;

      &.selected,
      &:hover {
        .title {
          color: #E80000;
        }
      }
      &.selected {
        background-color: #EBEBEB;
      }
    }

    .title {
      display: inline-block;
      color: #333333;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      padding-left: 24px;
    }
  }
  .items {
    &.active,
    &.selected {
      background-color: #fff;
      border-left: solid $borderWidth $mainColor;
      .item {
        margin: 0;
      }
    }
  }
}
</style>
