<template>
  <div class="FaqClientWindow mobile" ref="faqClientTag">
    <div class="content">
      <div class="search" ref="taggedInput" id="sai-tag-search-input">
        <TaggedInput
          :selectedTags="selectedTags.values()"
          :searchText="searchText"
          :inputEmpty="inputEmpty"
          :filteredItems="filteredItems"
          @onTagRemoved="removeSelectedTag"
          @onSearchTextChanged="setSearchText"
          @setShow="setTagSearchShow"
        />
      </div>
    </div>
    <div class="main">
      <div class="recommend" v-show="$store.state.tagSearch.show">
        <div ref="tagList">
          <TagList
            :candidateTags="candidateTags"
            :tagListTop="tagListTop"
            :inputEmpty="inputEmpty"
            :searchText="searchText"
            @onSelectTag="addSelectedTag"
            @setShow="setTagSearchShow"
          />
        </div>
        <div class="autocomplete custom-list">
          <Autocomplete
            v-if="!inputEmpty"
            :filteredItems="filteredItems"
            :inputEmpty="inputEmpty"
            @selected="selectItem"
            @setTagSearchShow="setTagSearchShow"
          />
          <Frequent v-if="inputEmpty" @setTagSearchShow="setTagSearchShow" />
        </div>
      </div>
      <div id="saifaq-content">
        <div class="sai-headline">{{ headlineText }}</div>
        <div class="breadWrap">
          <BreadList
            :bread="$store.state.navigation.routes"
            :index="$store.state.navigation.index"
            :routing="routing"
          />
        </div>
        <sAISearchCategory
          :scriptPackage="scriptPackage"
          :eventHub="eventHub"
          :column-width="categoryWidth"
          :height="categoryHeight"
        />
        <div v-show="isResultRoute" class="feedbackWrap">
          <ResourceListFooter
            :currentValue="$store.getters['navigation/getRoute']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import Const from '@/const/index';
import textParser from '@/common/textParser';
import { eventBus } from '@/eventBus';
import { Watch } from 'vue-property-decorator';
import resource from '@/common/resource';

@Component({})
export default class FaqClientWindow extends mixins(FaqClientTagMixin) {
  mainTitle = Const.MAIN_TITLE;
  searchInputTitle = Const.SEARCH_INPUT_TITLE;
  frequentTitle = Const.FREQUENT_TITLE;
  historyTitle = Const.HISTORY_TITLE;
  faqAnswerTitle = Const.FAQ_ANSWER_TITLE;
  searchCalcText = Const.SEARCH_CALC_TEXT;
  headlineText = Const.HEADLINE;
  eventHub = eventBus;
  routing = Const.ROUTING;

  scrollContainer = 'body';
  scrollTopElement = '#hisApp';
  scrollFaqElement = '#sai-scenario';
  scrollAnswerElement = '#sai-scenario-answer';
  scrollSearchElement = '#sai-tag-search-input';
  autoScrollElement = '#sai-tag-search-input';

  existsCategory(text) {
    return textParser.existsCategory(text);
  }
  generateCategories(text) {
    return textParser.generateCategories(text);
  }
  generateTitle(text) {
    return textParser.generateTitle(text);
  }
  autoScroll() {
    if (this.initAutoScroll) {
      this.setInitAutoScroll(false);
      return;
    }
    if (!this.$store.state.tagSearch.show) {
      const target = this.$store.state.user.isIphone
        ? this.scrollTopElement
        : this.autoScrollElement;
      this.scrollTo(target, {
        container: this.scrollContainer,
        x: false,
        duration: 1000,
      });
    }
  }
  async setTagSearchShow(show) {
    // if (!show) {
    //   await this.tagSearchStore.dispatch('tagifySeachText');
    // }
    setTimeout(() => {
      this.$store.commit('tagSearch/setShow', show);
    }, 0);
  }
  get categoryWidth() {
    return window.innerWidth - 25;
  }
  get categoryHeight() {
    return `${window.innerHeight}px`;
  }
  get scriptPackage() {
    const data = resource.ready();
    return data;
  }
  get isResultRoute() {
    return this.$store.getters['navigation/getRoute'].viewType === 'result';
  }
  changeRoute({ routes, index }) {
    this.$store.commit('navigation/setIndex', index);
    this.$store.commit('navigation/setRoutes', routes);
  }
  setScript(item) {
    if (!this.routing) {
      return;
    }
    const talkScriptId = item.talkScriptId
      ? item.talkScriptId
      : this.$route.params.talkScriptId;
    this.$router.push(talkScriptId);
  }
  setResultScript(item) {}
  postTicket(item) {
    const action = item.viewType === 'result' ? 'answeredAction' : 'openAction';
    const params = {
      userId: this.$store.state.user.Id,
      device: this.$store.state.user.device,
      osName: this.$store.state.user.osName,
      browserName: this.$store.state.user.browserName,
      log_faq_parent_category: item.items.log_faq_parent_category,
      log_faq_child_category: item.items.log_faq_child_category,
      log_faq: item.items.log_faq,
      log_faq_title: item.items.log_faq_title,
      log_scenario: item.items.log_scenario,
      log_faq_channel: 'search',
    };
    params.query = this.$store.state.tagSearch.searchText || '-';
    const keywordTags = this.$store.state.tagSearch.selectedTags
      .values()
      .filter(t => {
        return t.type === 'keyword';
      });
    if (keywordTags.length) {
      params.log_tag_name = keywordTags.map(t => t.text);
      params.log_tag_display_name = keywordTags.map(
        t => t.displayText || t.text
      );
      params.log_tag_id = keywordTags.map(t => t.id);
    } else {
      params.log_tag_name = [];
      params.log_tag_display_name = [];
      params.log_tag_id = [];
    }
    const ticketData = {
      action,
      params,
    };
    this.$ticket.handleTicket(ticketData);
  }
  created() {
    eventBus.$on('changeRoute', this.changeRoute);
    eventBus.$on('setScript', this.setScript);
    eventBus.$on('setResultScript', this.setResultScript);
    eventBus.$on('postTicket', this.postTicket);
  }
  mounted() {
    window.addEventListener('popstate', () => {
      if (!this.routing) {
        return;
      }
      const talkScriptId = this.$route.params.talkScriptId;
      const stepEncoded = this.$route.params.stepEncoded;
      if (!talkScriptId) {
        eventBus.$emit('selectScriptItem', '#');
        this.$router.push('/');
        return;
      }
      stepEncoded
        ? eventBus.$emit(
            'selectScenarioItemByStepEncoded',
            talkScriptId,
            stepEncoded
          )
        : eventBus.$emit('selectScriptItem', talkScriptId);
    });
  }
  @Watch('$store.state.navigation.routes', { immediate: true })
  routesUpdate() {
    if (this.$store.getters['isReady']) {
      const route = this.$store.getters['navigation/getRoute'];
      this.$nextTick(() => {
        eventBus.$emit('selectScriptItem', route.talkScriptId);
        this.$store.commit('tagSearch/setShow', false);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.FaqClientWindow {
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 10px;
  // line-height: 1.5em;
}

.content {
  display: block;
  position: relative;

  .header {
    padding: 15px 15px 0 15px;
    background-color: #f4f4f9;

    &__icon {
      display: inline-block;
      width: 27px;
      height: 27px;
      margin-right: 8px;
      border-radius: 50px;
      text-align: center;
      vertical-align: middle;
      background-color: #05244a;

      img {
        width: 18px;
        height: 18px;
        margin-top: 4px;
      }
    }
    &__title {
      display: inline-block;
      vertical-align: middle;
      color: #2c2a29;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .search {
    background-color: #05244a;
    padding: 8px;
  }
}

.recommend {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1500;
  background-color: #fff;
  .autocomplete {
    overflow-y: scroll;
    background-color: #fff;
  }
}

.main {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.resultContent {
  text-align: left;
  color: #2c2a29;
  .hasScenario & {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.sai-headline {
  margin: 10px 20px 0px;
}

.breadWrap {
  margin: 10px;
}

.feedbackWrap {
  position: fixed;
  bottom: 8px;
  left: auto;
  right: auto;
  width: 100%;
  padding: 0px 16px;
  background: rgb(255, 255, 255);
}
</style>
