const REG_FAQ = new RegExp(/[【［[](FAQ.*?)[\]］】]/);

const generateTitle = text => {
  if (!text) {
    return '';
  }
  const c = text.match(REG_FAQ);
  return c ? text.replace(REG_FAQ, '') : text;
};

const generateFaq = text => {
  if (!text) {
    return '';
  }
  return text.match(REG_FAQ) ? text.match(REG_FAQ)[1] : '';
};

const existsFaq = text => {
  if (!text) {
    return false;
  }
  return text.match(REG_FAQ);
};

const textParser = {
  existsFaq,
  generateTitle,
  generateFaq,
};

export default textParser;
