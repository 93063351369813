<template>
  <div class="sai-tag-list">
    <div class="sai-tag-list__head">
      <div class="sai-tag-list__head__title">
        <span class="text">{{ tagTitle }}</span>
      </div>
    </div>
    <div class="sai-tag-list__tags" v-if="!$store.state.user.isSP">
      <a
        class="sai-tag-list__tags__tag"
        v-for="(candidate, index) in filteredCandidateTags"
        v-bind:key="index"
        v-if="index < 6 && candidate.tag"
        @click.prevent="selectTag(candidate)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ '#' + ' ' + convertTagText(candidate) }}
        </span>
      </a>
    </div>
    <div class="sai-tag-list__tags" v-if="$store.state.user.isSP">
      <a
        class="sai-tag-list__tags__tag"
        v-for="(candidate, index) in filteredCandidateTags"
        v-bind:key="index"
        v-if="index < 6 && candidate.tag"
        @click.prevent="selectTag(candidate)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ '#' + ' ' + convertTagText(candidate) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TagListMixin from '@/mixins/TagListMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';

@Component({})
export default class TagList extends mixins(
  TagListMixin,
  ProductTicketMixin
  ) {
  get tagTitle() {
    return this.inputEmpty ? Const.TAG_POPULAR_TITLE : Const.TAG_RECOMMEND_TITLE;
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return { ...newTicket, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
$mainColor: #BF0000;
$subColor: #E80000;
$backgroundColor: #EBEBEB;
$tagBgColor: #FFF0F0;

.sai-tag-list {
  padding: 0 36px 12px 36px;
  background-color: $backgroundColor;
  .mobile & {
    display: flex;
    padding: 0 6px;
    background-color: #ffffff;
  }

  &__head {
    padding: 0 0 6px 0;

    .mobile & {
      display: none;
    }

    &__title {
      display: flex;
      align-items: center;

      .text {
        line-height: 24px;
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  &__tags {
    flex: 1 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      display: inline-block;
      background-color: $tagBgColor;
      color: $mainColor;
      border-radius: 14px;
      padding: 4px 10px 4px 8px;
      margin: 0 6px 6px 0;
      pointer-events: none;
      font-size: 12px;
      cursor: pointer;
      pointer-events: auto;

      .mobile & {
        background-color: $tagBgColor;
        font-size: 12px;
        border-radius: 16px;
      }

      &:hover {
        color: $subColor;
        opacity: 0.7;
        -ms-opacity: 0.7;
        .mobile & {
          color: $mainColor;
          opacity: 1;
          -ms-opacity: 1;
        }
      }

      &__content {
        display: inline-block;
      }
    }
  }
}
</style>
