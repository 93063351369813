<template>
  <span v-html="htmlString"></span>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    text: String,
    targets: Array,
  },
})
export default class Highlight extends Vue {
  get htmlString() {
    if (!this.targets || !this.targets.length) {
      return this.text;
    }

    const patternStr = '(?:' + this.targets.join('|') + ')';
    const caseInsensitiveMatching = new RegExp(patternStr, 'gi');

    return this.text.replace(caseInsensitiveMatching, matched =>
      this.stringDecorator(matched)
    );
  }

  stringDecorator(str) {
    return `<span class="hl">${str}</span>`;
  }
}
</script>
<style lang="scss" scoped>
/deep/ .hl {
  background-color: yellow;
}
</style>
