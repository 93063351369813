<template>
  <div class="multimodal">
    <div
      :class="['modal', modal.className || '']"
      v-for="(modal, index) in modals"
      :key="index"
    >
      <div class="modalBackdrop"></div>
      <div class="modalContent">
        <component
          :is="modal.component"
          v-bind="modal.props"
          @closeModal="closeModal($event, index)"
          @openModal="openModal($event, index)"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { modalService } from '@/common/modalService';

@Component({
  // components: {},
})
export default class Multimodal extends Vue {
  data() {
    return {
      modals: [
        // {
        //   component: ModalSample,
        // },
        // {
        //   component: ModalSample,
        // },
      ],
    };
  }
  closeModal(data, index = this.modals.length - 1) {
    this.modals.splice(index, this.modals.length - index);
  }
  openModal(modal, index = this.modals.length - 1) {
    this.modals.splice(index + 1, 1, modal);
  }
  created() {
    modalService.register(this);
  }
}
</script>

<style lang="scss" scoped>
.multimodal,
.modalBackdrop,
.modalContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.multimodal {
  pointer-events: none;
  z-index: 1001;
}
.modalBackdrop {
  background: #888;
  opacity: 0.5;
}
.modalContent {
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  background: #fff;
  border-radius: 10px;
  pointer-events: auto;
  .modal:nth-child(3n + 1) & {
    top: 10px;
    right: 50px;
    bottom: 50px;
    left: 10px;
  }
  .modal:nth-child(3n + 2) & {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
  }
  .modal:nth-child(3n) & {
    top: 50px;
    right: 10px;
    bottom: 10px;
    left: 50px;
  }
}
</style>
