export default {
  namespaced: true,
  state() {
    return {
      origin: '',
      pageType: '',
      embeddedUrl: '',
    };
  },
  mutations: {
    setOrigin(state, payload) {
      state.origin = payload;
    },
    setPageType(state, payload) {
      state.pageType = payload;
    },
    setEmbeddedUrl(state, payload) {
      state.embeddedUrl = payload;
    },
  },
  actions: {
    init({ commit }, { origin, pageType, embeddedUrl }) {
      commit('setOrigin', origin);
      commit('setPageType', pageType);
      commit('setEmbeddedUrl', embeddedUrl);
    },
  },
};
