<template>
  <div class="scrollY FaqClientTab">

    <div class="search">
      <div class="container partial">
        <h1 class="search__title">検索して探す</h1>
        <TaggedInput
          :tags="selectedTags.values()"
          @onTagRemoved="removeSelectedTag"
          :searchText="searchText"
          @onSearchTextChanged="setSearchText"
          :tagSearchStore="tagSearchStore"
        />
         <div style="position:relative;z-index:1800;background-color:white">
          <TagList :tags="candidateTags" @onSelectTag="addSelectedTag" />
         </div>
      </div>
    </div>
    <div v-show="ready">
      <div class="container partial">
        <div v-if="filteredItems">
          {{ filteredItems.length }}の質問が見つかりました
        </div>
        <ListContent
          class="listWrapper"
          :list="filteredItems"
          @selected="selectItem"
        />
      </div>
      <div
        class="scenario"
        v-show="sections.scenario.length || sections.result.length"
        :class="{ hasScenario: sections.scenario.length }"
      >
        <div class="scenario__inner">
          <a id="scenarioArea"></a>
          <section
            :class="{
              scenario__section: true,
              active: sections.talkScript.end === navigationStore.state.index,
              hideSection: true,
              section: true,
            }"
          >
            <div class="container partial">
              <h2
                class="sectionTitle--scenario"
                v-if="navigationStore.state.routes[sections.talkScript.end]"
              >
                <span class="sectionTitle__inner--scenario">
                  <i class="sectionTitle--scenario__icon"></i>
                  {{
                    navigationStore.state.routes[sections.talkScript.end] &&
                      navigationStore.state.routes[sections.talkScript.end].text
                  }}
                </span>
              </h2>
              <VerticalNavigation
                v-if="sections.scenario.length > 0"
                :navigationStore="navigationStore"
                :range="sections.scenario"
              />
            </div>
          </section>
          <section
            :class="{ active: false, answer: true }"
            v-if="sections.result.length"
          >
            <div class="answer__inner">
              <div class="step">
                <h3 class="step__title step__title--answer">
                  <i class="step__title--answer__icon"></i><span>回答</span>
                </h3>
                <div class="resultContent">
                  <VerticalNavigation
                    v-if="sections.result.length > 0"
                    :navigationStore="navigationStore"
                    :range="sections.result"
                  />
                </div>
              </div>
            </div>
          </section>
          <!--  -->
        </div>
      </div>
    </div>

    <div class="container partial" style="margin-top: 40px;">
      <h1 class="sectionTitle">よくある質問</h1>
      <Frequent :navigationStore="navigationStore"></Frequent>
    </div>
    <div class="container partial">
      <h1 class="sectionTitle">最近見たFAQ</h1>
      <History
        :navigationStore="navigationStore"
        :historyStore="historyStore"
      ></History>
    </div>
    <div style="height: 480px"></div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import { getViewSections } from '@/common/resourceNavigationUtil';

@Component({})
export default class FaqClientTag extends mixins(FaqClientTagMixin) {
  ready = true;
  get sections() {
    return getViewSections(this.navigationStore.state.routes);
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
@import '../style/component/FaqClientPc';

.listWrapper {
  height: 300px;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

ul {
  height: 300px;
  list-style-type: none;
  padding: 0;
  text-align: left;
  border: 1px solid #eee;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}

li {
  /* display: inline-block; */
  border: 1px solid #eee;
  margin: -1px;
  &.active {
    background: #eee;
    a {
      cursor: default;
    }
  }
  a {
    padding: 5px;
    height: 100%;
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    color: #333;
    cursor: pointer;
  }
}
</style>
