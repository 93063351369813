<template>
  <div
    class="sai-image-tag"
    :class="hoverCondition && 'mo'"
    @mouseover="toggleOnMouse(true)"
    @mouseleave="toggleOnMouse(false)"
    @click.prevent="$emit('selectTag')"
  >
    <div class="icon">
      <img
        v-show="!hoverCondition"
        :alt="text"
        :src="iconSrc(icon, type)"
      />
      <img
        v-show="hoverCondition"
        :alt="text"
        :src="iconHoverSrc(icon, type)"
      />
    </div>
    <div class="border" v-if="type === 'sp'"></div>
    <div
      class="text"
      v-html="displayText"
    ></div>
  </div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    text: String,
    displayText: String,
    icon: String,
    type: String,
  },
})
export default class ImageTag extends Vue {
  onMouse = false;
  onHover = false;

  get hoverCondition() {
    return this.onMouse && this.onHover;
  };

  toggleOnMouse(boolean) {
    this.onMouse = boolean;
  }
  iconSrc(file, path) {
    return require(`../resource/${process.env.BUILD_MODE}/img_tags/${path}/default/${file}`);
  }
  iconHoverSrc(file, path) {
    return require(`../resource/${process.env.BUILD_MODE}/img_tags/${path}/light/${file}`);
  }
  created() {
    if (this.$store.state.product.origin === 'page') {
      this.onHover = true;
    }
  }
}
</script>
<style lang="scss" scoped>
$mainColor: #BF0000;
$hoverColor: #E80000;

.sai-image-tag {
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  height: 100%;

  .mobile & {
    border: solid 1px $mainColor;
    border-radius: 4px;
    padding: 4px 2px 4px 8px;
  }

  .icon {
    flex-shrink: 0;
    margin-right: 8px;
    opacity: 1;

    .mobile & {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      height: 100%;
      width: 20%;
    }

    img {
      position: relative;
      height: 32px;
      width: 32px;
    }
  }

  .border {
    width: 2px;
    height: 32px;
    background-color: #ccc;
    margin-right: 8px;
  }

  .text {
    width: 100%;
    color: $mainColor;
    font-size: 16px;

    .mobile & {
      font-size: 14px;
    }

    span {
      display: inline-block;
    }

    /deep/ br {
      display: none;
      .mobile & {
        display: inline;
      }
    }
  }

  &.mo {
    .text {
      color: $hoverColor;
    }
  }
}
</style>
