import search from '@/common/search';
import dataResource from '@/common/dataResource';

export default {
  namespaced: true,
  state: {
    show: false,
    result: [],
    showFaqTitle: true,
    searchText: '',
    searchCategories: [],
  },
  getters: {
    getSearchCategories: state => {
      return state.searchCategories;
    },
    getSearchText: state => {
      return state.searchText;
    },
  },
  mutations: {
    setShow(state, payload) {
      state.show = payload;
    },
    setResult(state, payload) {
      state.result = payload;
    },
    setSearchText(state, payload) {
      state.searchText = payload;
    },
    setSearchCategories(state, payload) {
      state.searchCategories = payload;
    },
  },
  actions: {
    async init({ dispatch }) {
      await dispatch('setSearchText');
    },
    async setSearchText({ getters, commit }, searchText) {
      let text = searchText;
      if (getters.getSearchCategories.length > 0) {
        text =
          getters.getSearchCategories.map(c => c.text).join(' ') +
          ' ' +
          searchText;
      }
      const ids = await search.search(text || null);
      if (!searchText || ids.length > 0) {
        const result =
          ids &&
          ids.map(talkScriptId =>
            dataResource.getItemSync({
              resouceName: 'talkScript',
              talkScriptId,
            })
          );
        commit('setResult', result);
      } else {
        commit('setResult', []);
      }
      commit('setSearchText', searchText);
    },
    async addSearchCategories({ getters, commit, dispatch }, searchCategories) {
      commit('setSearchCategories', searchCategories);
      await dispatch('setSearchText', getters.getSearchText);
    },
    async removeSearchCategory(
      { getters, commit, dispatch },
      searchCategories
    ) {
      const newCategories = getters.getSearchCategories.filter(
        c => c !== searchCategories
      );
      commit('setSearchCategories', newCategories);
      await dispatch('setSearchText', getters.getSearchText);
    },
    async removeLastCategory({ getters, commit, dispatch }) {
      const lastCategory = [...getters.getSearchCategories].pop();
      await dispatch('removeSearchCategory', lastCategory);
    },
    async resetSearchCategories({ getters, commit, dispatch }) {
      commit('setSearchCategories', []);
      await dispatch('setSearchText', getters.getSearchText);
    },
    async resetAutoCompleteInput({ commit, dispatch }) {
      commit('setSearchCategories', []);
      await dispatch('setSearchText', '');
    },
  },
};
