import { generateStore } from '@/store/index';
import history from './modules/history';
import ticket from './modules/ticket';
import product from './modules/product';

const customizedStore = generateStore({
  modules: {
    history,
    ticket,
    product,
  },
});

export default customizedStore;
